import style from "./Login.module.scss";
import { LoginForm } from "./login-form/LoginForm";
import ImageLogin from "../../../../components/images/login/image-login.svg";
import { Col, ConfigProvider, Row, theme } from "antd";

const { defaultAlgorithm } = theme;

export const Login = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: defaultAlgorithm,
      }}>
      <Row justify="center" align="middle" className={style.main}>
        <img className={style.image} src={ImageLogin} alt="" />
        <Col xs={24} sm={18} md={16} lg={10} className={style.box}>
          <LoginForm />
        </Col>
      </Row>
    </ConfigProvider>
  );
};
