import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { EnvironmentService } from "./envoriment.service";
import { LocalStorageService } from "./local-storage.service";

const getBaseUrl = (service: string) => {
  if (EnvironmentService.isProd)
    return `https://api-portal.linkfyscale.com/${service}/graphql`;
  if (EnvironmentService.isStg)
    return `https://api-portal-stg.linkfyscale.com/${service}/graphql`;
  if (EnvironmentService.isDev)
    return `https://api-portal-dev.linkfyscale.com/${service}/graphql`;
  return "https://localhost:5001/graphql";
};

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization:
        `Bearer ${LocalStorageService.getLocalStorage(
          LocalStorageService.localStorageToken
        )}` || null,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  const notAuthorized =
    graphQLErrors!.findIndex(
      (_) => _.extensions?.code === "AUTH_NOT_AUTHENTICATED"
    ) >= 0;
  if (notAuthorized) {
    window.location.href = `/logout`;
    return;
  }
});

const httpLinkAuth = createHttpLink({
  uri: getBaseUrl("auth"),
});

const clientAuth = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkAuth])),
  cache: new InMemoryCache(),
});

const httpLinkDebtors = createHttpLink({
  uri: getBaseUrl("debtors"),
});

const clientDebtors = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkDebtors])),
  cache: new InMemoryCache(),
});

export { clientAuth, clientDebtors };

