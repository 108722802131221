import {
  ColumnsState,
  ProDescriptionsItemProps,
} from "@ant-design/pro-components";
import { useMutation, useQuery } from "@apollo/client";
import { Tag } from "antd";
import { useEffect, useState } from "react";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useMain } from "../../../../contexts/main";
import { clientDebtors } from "../../../../services/graphql.service";
import { mask } from "../../../../utils/mask";
import { Sort } from "../../../account/pages/users/Users.functions";
import {
  getDebtorByIdQuery,
  sendWhatsMessageAppQuery,
  updateSendWhatsAppQuery,
} from "../../services/Debtors.services";

export interface PartnerModel {
  id: string;
  documentNumber: string;
  createdAt: Date;
  name: string;
  debtorDocumentNumber: string;
  cellPhones: string[];
  emails: string[];
  status: string;
  resultIpBox: ResultIpBoxModel;
}

export interface ResultIpBoxModel {
  result: string;
  date: string;
  hour: string;
  cellPhone: string;
}

export const formatNameStatus = (status: string): string => {
  switch (status) {
    case "AWAIT_SEND_MESSAGE":
      return "Aguardando envio de mensagem no WhatsApp";
    case "SENDING_MESSAGE":
      return "Enviando mensagem no WhatsApp";
    case "SEND_MESSAGE":
      return "Enviada mensagem no WhatsApp";
    case "SEND_MESSAGE_WITH_FAILURE":
      return "Falha no envio da mensagem no WhatsApp";
    default:
      return "Importado";
  }
};

export const formatColorStatus = (status: string): string => {
  switch (status) {
    case "AWAIT_SEND_MESSAGE":
      return "success";
    case "SEND_MESSAGE":
      return "success";
    case "SEND_MESSAGE_WITH_FAILURE":
      return "error";
    default:
      return "default";
  }
};

export const SendWhatsAppFunctions = () => {
  const { handleChangeBreadcumb, successMessage, errorMessage, setLoading } =
    useMain();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entity, setEntity] = useState<PartnerModel | null>();
  const [srcImage, setSrcImage] = useState<string>();
  const [url, setUrl] = useState<string>();
  const { refetch } = useQuery(getDebtorByIdQuery(), {
    client: clientDebtors,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onError: () => {},
  });

  const handleOpenCloseModal = (entity?: PartnerModel) => {
    if (!entity) {
      setIsModalOpen(false);
      setEntity(null);
      return;
    }
    setIsModalOpen(!isModalOpen);
    setEntity(entity);
  };

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Leads",
      },
    ]);
  }, []);

  const columns = (): ProColumnsProps<PartnerModel>[] => [
    {
      title: "CNPJ",
      dataIndex: "debtorDocumentNumber",
      search: false,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "CPF",
      dataIndex: "documentNumber",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Nome",
      dataIndex: "name",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      filtered: true,
      sorter: true,
      valueType: "dateRange",
      render: (dom, entity) => {
        return new Date(entity.createdAt).toLocaleString("pt-BR");
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      search: false,
      valueType: "select",
      initialValue: ["AWAIT_SEND_MESSAGE", "SENDING_MESSAGE", "SEND_MESSAGE"],
      request: async (params, props) => {
        return [
          {
            label: "Aguardando envio de mensagem no WhatsApp",
            value: "AWAIT_SEND_MESSAGE",
          },
          {
            label: "Enviando mensagem no WhatsApp",
            value: "SENDING_MESSAGE",
          },
          {
            label: "Enviada mensagem no WhatsApp",
            value: "SEND_MESSAGE",
          },
          {
            label: "Falha no envio da mensagem no WhatsApp",
            value: "SEND_MESSAGE_WITH_FAILURE",
          },
        ];
      },
      render(dom, entity) {
        return (
          <Tag color={formatColorStatus(entity.status)}>
            {formatNameStatus(entity.status)}
          </Tag>
        );
      },
    },
    // {
    //   title: "Ações",
    //   dataIndex: "action",
    //   search: false,
    //   render(dom, entity, index, action, schema) {
    //     return (
    //       <Row>
    //         <Col>
    //           <Button
    //             type="text"
    //             shape="circle"
    //             onClick={() => handleOpenCloseModal(entity)}
    //             icon={<HiEye />}
    //           />
    //         </Col>
    //       </Row>
    //     );
    //   },
    // },
  ];

  const columnsPartners = (): ProDescriptionsItemProps<ResultIpBoxModel>[] => [
    {
      title: "CPF",
      dataIndex: "documentNumber",
      ellipsis: true,
      render(dom, entity) {
        return (
          <span>
            {mask(
              entity.cellPhone,
              entity.cellPhone.length == 11
                ? "(99) 9 9999-9999"
                : "(99) 9999-9999"
            )}
          </span>
        );
      },
    },
    {
      title: "Result",
      dataIndex: "result",
      ellipsis: true,
    },
    {
      title: "Data",
      dataIndex: "date",
      ellipsis: true,
    },
    {
      title: "Hora",
      dataIndex: "hour",
      ellipsis: true,
    },
  ];

  const whereDefault = {
    status: { in: ["AWAIT_SEND_MESSAGE", "SENDING_MESSAGE", "SEND_MESSAGE"] },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 50,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    documentNumber: {
      show: true,
    },
    name: {
      disable: true,
      show: true,
    },
    debtorDocumentNumber: {
      show: false,
    },
    createdAt: {
      show: true,
    },
    status: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const [columnsCsv, setColumnsCsv] = useState<string[]>([
    "documentNumber",
    "name",
    "debtorDocumentNumber",
    "createdAt",
    "resultIpBox",
  ]);

  const [dateSync, setDateSync] = useState<string[]>();
  const [message, setMessage] = useState<string>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [sendWhatsMessageAppMutation] = useMutation(
    sendWhatsMessageAppQuery(),
    {
      client: clientDebtors,
    }
  );

  const onSendMessage = () => {
    setLoading(true);
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      errorMessage(
        "É necessário selecionar pelo menos um sócio para enviar a mensagem."
      );
      setLoading(false);
      return;
    }

    if (!message) {
      errorMessage("É necessário digitar a mensagem.");
      setLoading(false);
      return;
    }

    let request = {
      partners: selectedRowKeys.map((item) => ({ id: item })),
      message: message,
      imageUrl: url
        ? `https://533267310281-b2l-temp.s3.amazonaws.com/${url}`
        : null,
    };
    sendWhatsMessageAppMutation({
      variables: {
        request,
      },
    })
      .then((result) => {
        if (result.errors) {
          errorMessage("Houve um erro ao realizar o envio da mensagem.");
          return;
        }

        const { success, message } = result.data.sendWhatsApp;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar o envio da mensagem."
          );
          return;
        } else {
          successMessage("Mensagem enviada com sucesso!");
        }
      })
      .catch((error) => {
        errorMessage("Houve um erro ao realizar o envio da mensagem.");
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [updateSendWhatsAppMutation] = useMutation(updateSendWhatsAppQuery(), {
    client: clientDebtors,
  });

  const onSync = () => {
    setLoading(true);
    if (!dateSync) {
      errorMessage("É necessário selecionar o intervalo da sincronização.");
      setLoading(false);
      return;
    }

    setLoading(true);
    updateSendWhatsAppMutation({
      variables: {
        request: {
          start: dateSync[0],
          end: dateSync[1],
        },
      },
    })
      .then((result) => {
        if (result.errors) {
          errorMessage("Houve um erro ao realizar a sincronização dos dados.");
          return;
        }

        const { success, message } = result.data.updateSendWhatsApp;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a sincronização dos dados."
          );
          return;
        } else {
          successMessage(message ?? "Sincronização realizada com sucesso!");
        }
      })
      .catch((error) => {
        errorMessage("Houve um erro ao realizar a sincronização dos dados.");
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hasSelected = () => {
    return selectedRowKeys && selectedRowKeys.length == 0 && !!message;
  };

  const [screenshot, setScreenshot] = useState<string>();
  const sleeping = 5000;

  useEffect(() => {
    const interval = setInterval(async () => {
      await fetch(
        "https://api-portal-whatsapp.linkfyscale.com:3000/api/screenshot?session=default"
      ).then(async (response) => {
        if (response.status === 422) {
          await fetch("https://api-portal-whatsapp.linkfyscale.com:3000/api/sessions/start", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              session: "default",
            }),
          });
        }
        var result = await response.blob();
        const imageObjectURL = URL.createObjectURL(result);
        setScreenshot(imageObjectURL);
      });
    }, sleeping);

    return () => clearInterval(interval);
  }, []);

  return {
    columns,
    columnsMap,
    clientDebtors,
    whereDefault,
    variables,
    setVariables,
    isModalOpen,
    entity,
    handleOpenCloseModal,
    refetch,
    columnsPartners,
    columnsCsv,
    srcImage,
    setSrcImage,
    setDateSync,
    onSync,
    setSelectedRowKeys,
    onSendMessage,
    message,
    setMessage,
    hasSelected,
    screenshot,
    url,
    setUrl,
  };
};
