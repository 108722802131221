import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { useState } from "react";
import { v4 } from "uuid";
import { EnvironmentService } from "../services/envoriment.service";
interface ICustomFileUpload extends File {
  fileKey: string;
  progress: number;
  fileExtensions: string | undefined | null;
  fileName: string;
}

type ICustomFileState<T extends string = "item"> = {
  [x in T]: ICustomFileUpload[];
};

const userUploadCredentials = {
  accessKeyId: "AKIAWTKPBYPEKS3MG6YO",
  secretAccessKey: "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",
};

const s3Client = new S3Client({
  region: "us-east-1",
  credentials: userUploadCredentials,
  requestHandler: new XhrHttpHandler({}),
});

const useUploadFiles = <T extends string = "item">() => {
  const [files, setFiles] = useState<ICustomFileState<T>>(
    {} as ICustomFileState<T>
  );

  const handleUploadS3 = async (
    bucketName: string,
    id: string,
    collectionName: string,
    fieldName: string,
    requestOptions: RcCustomRequestOptions,
    pathTemp?: string,
    identifyUpload?: T | "item"
  ) => {
    let { file, onProgress, onSuccess } = requestOptions;
    const _file = file as ICustomFileUpload;
    _file.fileExtensions =
      _file?.type?.split("/")![1]! || _file?.name?.split(".")![1]! || null;
    _file.fileKey = `${v4()}.${_file?.fileExtensions}`;
    _file.fileName = _file?.name?.split(".")[0];

    const params = {
      Bucket: bucketName,
      Key: pathTemp ? `${pathTemp}/${_file?.fileKey}` : `${_file?.fileKey}`,
      Body: _file,
      Metadata: {
        ModelId: id,
        CollectionName: collectionName,
        FieldName: fieldName,
        Environment: EnvironmentService.isProd
          ? "prod"
          : EnvironmentService.isStg
          ? "prod"
          : "dev",
      },
    };

    const key = (identifyUpload || "item") as string;

    setFiles((prev: any) => {
      return {
        ...prev,
        [key]: Array.isArray(prev[key]) ? [...prev[key], _file] : [_file],
      };
    });

    try {
      const upload = new Upload({
        client: s3Client,
        params: params,
        leavePartsOnError: true,
      });

      upload.on("httpUploadProgress", function (progress: any) {
        _file.progress = Math.round((progress?.loaded / progress?.total) * 100);
        if (onProgress) onProgress({ percent: _file?.progress });
      });

      await upload.done();

      if (onSuccess) onSuccess({ ...files });

      return { params };
    } catch (err) {
      console?.log("Error", err);
    }

    return { params };
  };

  return {
    files,
    setFiles,
    handleUploadS3,
  };
};

export { useUploadFiles };
export type { ICustomFileState, ICustomFileUpload };

