import React, { FunctionComponent, useEffect } from "react";
import { decodeToken } from "react-jwt";
import { LocalStorageService } from "../../../../services/local-storage.service";
import ImageAutoLogin from "../../../../components/images/auto-login/loading-platform.svg";
import styles from "./AutoLogin.module.scss";
import { Typography, theme } from "antd";

const AutoLogin: FunctionComponent = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const redirect = new URLSearchParams(window.location.search).get("redirect");
  const homePage = `${window.location.protocol}//${window.location.host}/${
    redirect ?? ""
  }`;

  let token = LocalStorageService.getLocalStorage(
    LocalStorageService.localStorageToken
  );

  const user = decodeToken<any>(token ?? "");

  const handleRedirectLogin = () => {
    window.location.pathname = "/logout";
  };

  useEffect(() => {
    if (user) {
      LocalStorageService.setLocalStorageJSON(
        LocalStorageService.localStorageUser,
        user
      );
      setTimeout(() => {
        window.location.href = homePage;
      }, 1000);
    } else {
      handleRedirectLogin();
    }
  }, []);

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <img src={ImageAutoLogin} className={styles["image"]} />
        <Typography.Title level={2} color={colorPrimary}>
          Seja bem vindo ao B2L!
        </Typography.Title>
      </div>
    </div>
  );
};

export { AutoLogin };
