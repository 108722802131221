import { InboxOutlined } from "@ant-design/icons";
import { ProFormDigitRange, ProFormSelect } from "@ant-design/pro-components";
import { Button, Card, Col, Form, Radio, Row, Table, Typography } from "antd";
import { BaseOptionType } from "antd/es/select";
import Dragger from "antd/es/upload/Dragger";
import { ImportDebtorsFunctions } from "./ImportDebtors.functions";
import styles from "./ImportDebtors.module.scss";

export const ImportDebtors = () => {
  const {
    columns,
    dataSourceDefault,
    dataSource,
    propsUpload,
    onSelectAll,
    selectedRowKeys,
    rowSelection,
    hasSelected,
    onReset,
    onImport,
    onChangeState,
    onChangeCity,
    onChangeValueRange,
    fetchStates,
    cities,
    batchs,
    setSelectedBatch,
    mailingUra,
    setMailingUra,
    loading,
  } = ImportDebtorsFunctions();

  return (
    <>
      <Card
        title="Importar devedores exportados do PGFN"
        extra={[
          <Row gutter={[16, 16]}>
            <Col>
              <Button onClick={onReset}>Resetar</Button>
            </Col>
          </Row>,
        ]}>
        <Row gutter={[16, 16]}>
          {!dataSourceDefault ||
            (dataSourceDefault?.length == 0 && (
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                      Faça o upload do .csv com os devedores a serem importados.
                    </Typography.Title>
                  </Col>
                  <Col span={24} className={styles["upload"]}>
                    <Dragger {...propsUpload}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Clique ou arraste o arquivo para esta área.
                      </p>
                      <p className="ant-upload-hint">
                        É permitido arquivos com a extensão: .csv <br />O .csv
                        deverá conter na primeira coluna o <strong>CNPJ</strong>
                        , seguido do <strong>Nome</strong> e na quarta coluna o{" "}
                        <strong>Valor</strong>.
                      </p>
                    </Dragger>
                  </Col>
                </Row>
              </Col>
            ))}
          {dataSourceDefault && dataSourceDefault?.length > 0 && (
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24} sm={4}>
                  <ProFormSelect
                    label="Lote"
                    options={batchs.map(
                      (batch) =>
                        ({
                          label: batch.descricao,
                          value: batch.descricao,
                        } as BaseOptionType)
                    )}
                    fieldProps={{
                      loading: loading,
                    }}
                    onChange={(value: any) => {
                      setSelectedBatch(value);
                    }}
                  />
                </Col>
                <Col span={24} sm={6}>
                  <ProFormSelect
                    label="UF"
                    showSearch
                    allowClear
                    request={fetchStates}
                    onChange={onChangeState}
                  />
                </Col>
                <Col span={24} sm={6}>
                  <ProFormSelect
                    label="Município"
                    showSearch
                    allowClear
                    mode="multiple"
                    options={cities}
                    onChange={onChangeCity}
                  />
                </Col>
                <Col span={24} sm={7}>
                  <ProFormDigitRange
                    label="Valor"
                    fieldProps={{
                      formatter: (value: any) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
                      parser: (value: any) =>
                        value?.replace(/\$\s?|(\.*)/g, "") as unknown as number,
                      onChange: onChangeValueRange,
                    }}
                  />
                </Col>
                <Col span={24} sm={5}>
                  <Form.Item label="Lote é URA ?">
                    <Radio.Group
                      onChange={(value) =>
                        setMailingUra(value.target.value)
                      }
                      value={mailingUra}>
                      <Radio value={true}>Sim</Radio>
                      <Radio value={false}>Não</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    disabled={!hasSelected}
                    onClick={onImport}>
                    Importar ({selectedRowKeys.length}) devedores
                  </Button>
                </Col>
              </Row>
              <Button type="link" onClick={onSelectAll}>
                {dataSource.length == selectedRowKeys.length
                  ? `Desmarcar todos`
                  : `Marcar todos`}
              </Button>
              <Table
                rowKey={"documentNumber"}
                rowSelection={rowSelection}
                dataSource={dataSource}
                columns={columns()}
              />
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
};
