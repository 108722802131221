import { useState } from "react";
import type { ColumnsState, ProColumns } from "@ant-design/pro-components";
import { Avatar, Button, Space, Tag, Tooltip } from "antd";
import { clientAuth } from "../../../../services/graphql.service";
import { FaLink, FaPencil, FaTrash } from "react-icons/fa6";
import { MdDelete, MdEdit, MdLink } from "react-icons/md";
import { useNavigate, useNavigation } from "react-router-dom";
import { AccountRoutes } from "../../../account/routes.enum";
import { PlusOutlined } from "@ant-design/icons";
import { useMain } from "../../../../contexts/main";
import { deleteUser } from "../../../account/services/account.service";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../../../contexts/auth";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";

type UserModulesModel = {
  id: string;
  name: string;
};

type AvatarModel = {
  path: string;
};

type UserGeralDataModel = {
  avatar: AvatarModel;
};

export interface UserModel {
  id: string;
  name: string;
  surname: string;
  email: string;
  modules: UserModulesModel[];
  geralData: UserGeralDataModel;
}

export interface Response<T> {
  nodes: T[];
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  totalCount: number;
}

export enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export const UsersAccountFunctions = () => {
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const onClickCreateUser = () => {
    navigate(AccountRoutes.UserCreateUser);
  };

  const onClickEditUser = (id: string) => {
    const url = AccountRoutes.UserEditUser.replace(":id", id);
    navigate(url);
  };

  const [deleteUserMutation] = useMutation(deleteUser(), {
    client: clientAuth,
  });
  const onClickDeleteUser = (id: string) => {
    deleteUserMutation({
      variables: {
        id,
      },
    });
  };

  const toolBarRender = () => [
    hasPermission(["admin", "admin-system"]) && (
      <Button
        key="button"
        icon={<PlusOutlined />}
        onClick={onClickCreateUser}
        type="primary">
        Novo
      </Button>
    ),
  ];

  const columns = (): ProColumnsProps<UserModel>[] => [
    {
      title: "Apelido",
      dataIndex: "surname",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Nome",
      dataIndex: "name",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      filters: true,
      onFilter: true,
      ellipsis: true,
      copyable: true,
      sorter: true,
    },
    {
      title: "Ações",
      key: "actions",
      search: false,
      width: 180,
      align: "center",
      permission: ["admin", "admin-system"],
      render: (_, record) => [
        <Space>
          <Tooltip title="Editar usuário">
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => onClickEditUser(record.id)}
            />
          </Tooltip>
          <Tooltip title="Deletar usuário">
            <Button
              type="link"
              shape="circle"
              icon={<MdDelete />}
              onClick={() => onClickDeleteUser(record.id)}
            />
          </Tooltip>
        </Space>,
      ],
    },
  ];

  const whereDefault = {
    name: {
      contains: "",
    },
    surname: {
      contains: "",
    },
    email: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 10,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    name: {
      show: true,
    },
    surname: {
      disable: true,
      show: true,
    },
    email: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  return {
    columns,
    columnsMap,
    clientAuth,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  };
};
