import { SyncOutlined, WhatsAppOutlined } from "@ant-design/icons";
import {
    ProDescriptionsItemProps,
    ProFormDateRangePicker,
    ProFormTextArea,
} from "@ant-design/pro-components";
import {
    Button,
    Card,
    Col,
    Divider,
    Image,
    Modal,
    Row,
    Typography,
} from "antd";
import { UploadField } from "../../../../components/avatar/update-file/update-file-modal/upload-field/UploadField";
import { ProDescription } from "../../../../components/descriptions/pro-description/ProDescription";
import { ProTable } from "../../../../components/table/pro-table/ProTable";
import {
    PartnerModel,
    ResultIpBoxModel,
    SendWhatsAppFunctions,
} from "./SendWhatsApp.functions";

export const SendWhatsApp = () => {
  const {
    columns,
    columnsMap,
    clientDebtors,
    whereDefault,
    variables,
    setVariables,
    isModalOpen,
    handleOpenCloseModal,
    entity,
    refetch,
    columnsPartners,
    columnsCsv,
    srcImage,
    setSrcImage,
    setDateSync,
    onSync,
    setSelectedRowKeys,
    onSendMessage,
    message,
    setMessage,
    hasSelected,
    screenshot,
    url,
    setUrl,
  } = SendWhatsAppFunctions();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} xxl={19}>
          <Card
            title="Informações para o envio da mensagem"
            extra={[
              <Button
                type="primary"
                icon={<WhatsAppOutlined />}
                disabled={hasSelected()}
                onClick={onSendMessage}>
                Enviar mensagem
              </Button>,
            ]}>
            <Row gutter={[8, 8]}>
              <Col span={24} xxl={18}>
                <ProFormTextArea
                  labelCol={{ span: 24 }}
                  label="Mensagem"
                  help="Utilize {{NOME}} no texto para substituir pelo nome do contato."
                  fieldProps={{
                    onChange: (event) => setMessage(event.target.value),
                    value: message,
                  }}
                />
              </Col>
              <Col span={24} xxl={6}>
                <Row gutter={[8, 18]}>
                  <Col span={24}>
                    <Typography.Text>Imagem</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <UploadField
                      bucketName="533267310281-b2l-temp"
                      collectionName=""
                      fieldName=""
                      id=""
                      src={srcImage ?? ""}
                      setSrc={setSrcImage}
                      setUrl={setUrl}
                      pathTemp="image-whatsapp"
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider />
                <Typography.Title level={4}>Sócios</Typography.Title>
                <ProTable<PartnerModel>
                  client={clientDebtors}
                  queryName="partners"
                  whereType="PartnerModelFilterInput"
                  whereDefault={whereDefault}
                  orderType="PartnerModelSortInput"
                  variables={variables}
                  setVariables={setVariables}
                  columns={columns()}
                  defaultColumnsMap={columnsMap}
                  columnsCsv={columnsCsv}
                  rowKey={"id"}
                  ghost
                  size={"small"}
                  onSelectChangeDefault={setSelectedRowKeys}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24} xxl={5}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Sincronizar dados com IPBox">
                <Row>
                  <Col span={24}>
                    <ProFormDateRangePicker
                      label="Intervalo da sincronização"
                      labelCol={{ span: 24 }}
                      style={{ width: "100%" }}
                      fieldProps={{
                        onChange(dates, dateStrings) {
                          setDateSync(dateStrings);
                        },
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      icon={<SyncOutlined />}
                      onClick={onSync}>
                      Sincronizar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Image width={"100%"} src={screenshot} />
            </Col>
          </Row>
        </Col>
      </Row>
      {isModalOpen && (
        <Modal
          width={1200}
          open={isModalOpen}
          cancelText="Fechar"
          cancelButtonProps={{
            hidden: true,
          }}
          okButtonProps={{
            hidden: true,
          }}
          onCancel={() => handleOpenCloseModal()}>
          <ProDescription
            title="Sócio"
            size="small"
            layout="horizontal"
            column={1}
            request={async (params) => {
              var result = await refetch({ id: entity?.id });
              return result.data.debtorById;
            }}
            columns={[
              ...columns()
                .filter((column: any) => column.dataIndex !== "action")
                .map(
                  (column) =>
                    ({
                      ...column,
                    } as ProDescriptionsItemProps)
                ),
              {
                title: "Resultados IPBox",
                dataIndex: "resultIpBox",
                span: 2,
                render: (dom, entity) => (
                  <Row gutter={[8, 8]}>
                    {entity.resultIpBox?.map(
                      (resultIpBox: ResultIpBoxModel) => (
                        <Col span={24}>
                          <Card title={`Telefone: ${resultIpBox.cellPhone}`}>
                            <ProDescription
                              dataSource={resultIpBox}
                              column={1}
                              layout="horizontal"
                              size="small"
                              columns={
                                columnsPartners() as ProDescriptionsItemProps[]
                              }
                            />
                          </Card>
                        </Col>
                      )
                    ) ?? (
                      <Typography.Text>
                        Nenhum resultado localizado.
                      </Typography.Text>
                    )}
                  </Row>
                ),
              },
            ]}
          />
        </Modal>
      )}
    </>
  );
};
