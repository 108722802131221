import { ProDescriptionsItemProps } from "@ant-design/pro-components";
import { Card, Col, Modal, Row, Typography } from "antd";
import { ProDescription } from "../../../../components/descriptions/pro-description/ProDescription";
import { ProTable } from "../../../../components/table/pro-table/ProTable";
import {
  DebtorsFunctions,
  DebtorsModel,
  PartnerModel,
} from "./Debtors.functions";

export const Debtors = () => {
  const {
    columns,
    columnsMap,
    clientDebtors,
    whereDefault,
    variables,
    setVariables,
    isModalOpen,
    handleOpenCloseModal,
    entity,
    refetch,
    columnsPartners,
    columnsCsv,
  } = DebtorsFunctions();

  return (
    <>
      <ProTable<DebtorsModel>
        headerTitle="Devedores"
        client={clientDebtors}
        queryName="debtors"
        whereType="DebtorModelFilterInput"
        whereDefault={whereDefault}
        orderType="DebtorModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        columnsCsv={columnsCsv}
        rowKey={"documentNumber"}
        fieldFilterCsv="documentsNumbers"
        fieldFilterTypeCsv="String"
        onFormatColumnsMapCsv={(columns) => {
          columns = columns?.replace(
            "partners",
            `partners {
              documentNumber
              name
              cellPhones
              emails
            }`
          );

          columns = columns?.replace(
            "failure",
            `failure {
              createdAt
              message
            }`
          );

          return columns;
        }}
      />
      {isModalOpen && (
        <Modal
          width={1200}
          open={isModalOpen}
          cancelText="Fechar"
          cancelButtonProps={{
            hidden: true,
          }}
          okButtonProps={{
            hidden: true,
          }}
          onCancel={() => handleOpenCloseModal()}>
          <ProDescription
            title="Lead"
            size="small"
            layout="horizontal"
            column={1}
            request={async (params) => {
              var result = await refetch({ id: entity?.id });
              return result.data.debtorById;
            }}
            columns={[
              ...columns()
                .filter((column: any) => column.dataIndex !== "action")
                .map(
                  (column) =>
                    ({
                      ...column,
                    } as ProDescriptionsItemProps)
                ),
              {
                title: "Sócios",
                dataIndex: "partners",
                span: 2,
                render: (dom, entity) => (
                  <Row gutter={[8, 8]}>
                    {entity.partners?.map((partner: PartnerModel) => (
                      <Col span={24}>
                        <Card title={`Sócio(a): ${partner.name}`}>
                          <ProDescription
                            dataSource={partner}
                            column={1}
                            layout="horizontal"
                            size="small"
                            columns={
                              columnsPartners() as ProDescriptionsItemProps[]
                            }
                          />
                        </Card>
                      </Col>
                    )) ?? (
                      <Typography.Text>Nenhum sócio localizado</Typography.Text>
                    )}
                  </Row>
                ),
              },
            ]}
          />
        </Modal>
      )}
    </>
  );
};
