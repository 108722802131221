import { Fragment } from "react";
import { HiHome } from "react-icons/hi2";
import { useRoutes } from "react-router-dom";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { useAuth } from "./contexts/auth";
import { AppRoute } from "./models/app-route.model";
import { getAccountRoutes } from "./modules/account/routes";
import { getAuthRoutes } from "./modules/auth/routes";
import { getDebtorsRoutes } from "./modules/debtors/routes";
import { MyUser } from "./modules/my-account/pages/my-user/MyUser";
import { flattenTreeToList } from "./utils/array";

function App() {
  let routes: AppRoute[] = [
    ...getAuthRoutes(),
    {
      name: "Leads",
      icon: <HiHome />,
      component: <MyUser />,
      permission: ["admin-system", "admin", "user"],
      routes: [...getDebtorsRoutes()],
    },
    {
      name: "Configurações",
      icon: <HiHome />,
      path: "settings",
      routes: [...getAccountRoutes()],
    },
  ];

  const { hasPermission } = useAuth();

  routes = routes.filter((_) => _.notPermission || hasPermission(_.permission));

  routes = routes.map((_) => ({
    ..._,
    routes: _.routes?.filter(
      (_) => _.notPermission || hasPermission(_.permission)
    ),
  }));

  const cloneRoutes: AppRoute[] = [...routes];

  let routesReducer = flattenTreeToList<AppRoute>(routes, "routes");
  routesReducer = routesReducer.filter(
    (_) => _.notPermission || hasPermission(_.permission)
  );
  routesReducer
    .filter((f: AppRoute) => !f.notUseMainLayout)
    ?.forEach((route: AppRoute) => {
      route.component = (
        <PrivateRoute routes={cloneRoutes} component={route.component} />
      );
    });

  routesReducer
    .filter((f: AppRoute) => !f.notUseMainLayout)
    .forEach((route: AppRoute) => {
      route.component = <Fragment>{route.component}</Fragment>;
    });

  return useRoutes(
    routesReducer.map((_: AppRoute) => ({
      path: _.path,
      element: _.component,
    }))
  );
}

export default App;
