import React, { useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { clientAuth } from "../../../../services/graphql.service";
import { getUserByIdQuery } from "../../services/user.service";
import { UserModel } from "../../../account/pages/users/Users.functions";

export const UserCreateAccountFunctions = () => {
  const [surname, setSurname] = useState<string>();
  const { user } = useMain();
  const { handleChangeBreadcumb, setTitlePage } = useMain();

  useEffect(() => {
    if (!user?.Id) {
    }

    const title = "Meu perfil";
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: title,
      },
    ]);

    setTitlePage(title);
  }, [user]);

  return { surname, setSurname };
};
