import { UserModel, UsersAccountFunctions } from "./Users.functions";
import { ProTable } from "../../../../components/table/pro-table/ProTable";
import { useEffect } from "react";
import { useMain } from "../../../../contexts/main";

export const UsersAccount = () => {
  const {
    columns,
    columnsMap,
    clientAuth,
    whereDefault,
    variables,
    setVariables,
    toolBarRender,
  } = UsersAccountFunctions();

  const { handleChangeBreadcumb } = useMain();

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Usuários",
      },
    ]);
  }, []);

  return (
    <>
      <ProTable<UserModel>
        headerTitle="Usuários"
        client={clientAuth}
        queryName="users"
        whereType="UserModelFilterInput"
        whereDefault={whereDefault}
        orderType="UserModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        toolBarRender={toolBarRender}
      />
    </>
  );
};
