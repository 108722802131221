import { useMutation, useQuery } from "@apollo/client";
import { Col, Row, Tag, UploadProps } from "antd";
import { ColumnsType } from "antd/es/table";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import { clientDebtors } from "../../../../services/graphql.service";
import { mask } from "../../../../utils/mask";
import {
  getLotesQuery,
  importNotEnrichedDebtorsQuery,
} from "../../services/Debtors.services";
import { LoteModel } from "../ura-debtors/UraDebtors.functions";

export interface ImportDirectDebtorsModel {
  documentNumber: string;
  name: string;
  fantasyName: string;
  partnerName: string;
  cellPhones: string[];
  emails: string[];
  city: string[];
  state: string;
}

export const ImportDirectDebtorsFunctions = () => {
  const [dataSourceDefault, setDataSourceDefault] = useState<
    ImportDirectDebtorsModel[]
  >([]);
  const [dataSource, setDataSource] = useState<ImportDirectDebtorsModel[]>([]);
  const { handleChangeBreadcumb, successMessage, errorMessage, setLoading } =
    useMain();

  const [batchs, setBatchs] = useState<LoteModel[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<LoteModel>();
  const [deletingExistents, setDeleteExistents] = useState<boolean>();
  const [mailingUra, setMailingUra] = useState<boolean>();

  const { loading } = useQuery(getLotesQuery(), {
    client: clientDebtors,
    onCompleted: (result) => {
      const { data } = result.lotes;
      onCompletedLotes(data ?? []);
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onCompletedLotes = (lotes: LoteModel[]) => {
    setBatchs(lotes ?? []);
  };

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Importar devedores",
      },
    ]);
  }, []);

  const propsUpload: UploadProps = {
    name: "file",
    accept: ".csv",
    customRequest({ onSuccess, onProgress }) {
      setTimeout(() => onProgress && onProgress({ percent: 50 }), 500);
      setTimeout(() => onProgress && onProgress({ percent: 75 }), 1000);
      setTimeout(() => onProgress && onProgress({ percent: 100 }), 1500);
      setTimeout(() => onSuccess && onSuccess("ok"), 2000);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log("uploading");
      }
      if (status === "done") {
        successMessage("Arquivo importado com sucesso!");
        changeHandler(info.file.originFileObj);
      } else if (status === "error") {
        errorMessage(
          `Ocorreu uma falha ao importar o arquivo ${info.file.name}.`
        );
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const columns = (): ColumnsType<ImportDirectDebtorsModel> => [
    {
      title: "CNPJ",
      dataIndex: "documentNumber",
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Nome",
      dataIndex: "name",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Nome fantasia",
      dataIndex: "fantasyName",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Estado",
      dataIndex: "state",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Cidade",
      dataIndex: "city",
      ellipsis: true,
      filtered: true,
      sorter: true,
      render(dom, entity) {
        return (
          <Row gutter={[8, 8]}>
            {entity.city?.map((city) => (
              <Col>
                <Tag>{city}</Tag>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: "Sócio",
      dataIndex: "partnerName",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Telefones",
      dataIndex: "cellPhones",
      ellipsis: true,
      filtered: true,
      sorter: true,
      render(dom, entity) {
        return (
          <Row gutter={[8, 8]}>
            {entity.cellPhones?.map((cellPhone) => (
              <Col>
                <Tag>
                  {mask(
                    cellPhone,
                    cellPhone.length == 11
                      ? "(99) 9 9999-9999"
                      : "(99) 9999-9999"
                  )}
                </Tag>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: "Emails",
      dataIndex: "emails",
      ellipsis: true,
      filtered: true,
      sorter: true,
      render(dom, entity) {
        return (
          <Row gutter={[8, 8]}>
            {entity.emails?.map((email) => (
              <Col>
                <Tag>{email}</Tag>
              </Col>
            ))}
          </Row>
        );
      },
    },
  ];

  const changeHandler = (file: any) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        handleForce(results.data);
      },
    });
  };

  const handleForce = (data: any) => {
    const newList = data
      .map((item: any) => Object.keys(item).map((key) => [key, item[key]][1]))
      .filter((item: string[]) =>
        /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/.test(item[0].substring(0, 18))
      )
      .map((item: string[]) => {
        if (!item) return null;
        return {
          documentNumber: item[0]?.replaceAll(/\"/g, ""),
          name: item[1]?.replaceAll(/\"/g, ""),
          fantasyName: item[2]?.replaceAll(/\"/g, ""),
          city: [item[8]?.replaceAll(/\"/g, "")].filter((item) => item),
          state: item[9]?.replaceAll(/\"/g, ""),
          partnerName: item[34]?.replaceAll(/\"/g, ""),
          cellPhones: [
            item[12]?.replaceAll(/\"/g, ""),
            item[13]?.replaceAll(/\"/g, ""),
          ].filter((item) => item),
          emails: [item[14]?.replaceAll(/\"/g, "")].filter((item) => item),
        } as unknown as ImportDirectDebtorsModel;
      })
      .filter((item: ImportDirectDebtorsModel) => item != null);
    setDataSource(newList);
    setDataSourceDefault(newList);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectAll = () => {
    if (dataSource.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      const newSelectedRowKeys = dataSource.map((item) => item.documentNumber);
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onReset = () => {
    setDataSourceDefault([]);
  };

  const [sendImportDebtorsMutation] = useMutation(
    importNotEnrichedDebtorsQuery(),
    {
      client: clientDebtors,
    }
  );

  const hasSelected = selectedRowKeys.length > 0 && selectedBatch;

  const onImport = () => {
    setLoading(true);
    const selecteds = dataSource.filter(
      (item: any) =>
        selectedRowKeys.findIndex((key) => key === item.documentNumber) > -1
    );
    if (!selecteds || selecteds.length === 0) {
      errorMessage(
        "É necessário selecionar pelo menos um devedor para importar."
      );
      setLoading(false);
      return;
    }

    sendImportDebtorsMutation({
      variables: {
        request: {
          leads: selecteds,
          deleteExisting: deletingExistents ? "Y" : "N",
          mailingUra: mailingUra ? "Y" : "N",
          batchName: selectedBatch,
        },
      },
    })
      .then((result) => {
        if (result.errors) {
          errorMessage("Houve um erro ao realizar a importação dos devedores.");
          return;
        }

        const { success, message } = result.data.importNotEnriched;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a importação dos devedores."
          );
          return;
        } else {
          onSelectChange([]);
          successMessage(message ?? "Devedores importados com sucesso!");
        }
      })
      .catch((error) => {
        errorMessage("Houve um erro ao realizar a importação dos devedores.");
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeValueRange = (value: any) => {
    setSelectedRowKeys([]);
    setDataSource(
      dataSourceDefault.filter(
        (item: any) => item.value > value[0] && item.value < value[1]
      )
    );
  };

  return {
    columns,
    dataSource,
    dataSourceDefault,
    handleForce,
    papaparseOptions,
    changeHandler,
    onSelectAll,
    selectedRowKeys,
    rowSelection,
    hasSelected,
    onReset,
    propsUpload,
    onImport,
    onChangeValueRange,
    batchs,
    setSelectedBatch,
    deletingExistents,
    setDeleteExistents,
    mailingUra,
    setMailingUra,
    loading,
  };
};
