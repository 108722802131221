import {
  Avatar,
  Card,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Tag,
} from "antd";
import { UserCreateAccountFunctions } from "./UserForm.functions";
import style from "./UserForm.module.scss";
import { UserOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { UserGeneralData } from "./user-general-data/UserGeneralData";
import { UserContacts } from "./user-contacts/UserContacts";
import { UserAvatar } from "./user-avatar/UserAvatar";

export const UserForm = () => {
  const { surname, setSurname, id } = UserCreateAccountFunctions();

  return (
    <Row>
      <Col span={24}>
        <Row justify={"center"}>
          <Col span={24}>
            <Row>
              <Col span={24} lg={6}>
                <Row justify={"center"} style={{ position: "sticky", top: 0 }}>
                  <Row justify={"center"}>
                    <Space direction="vertical">
                      <Row justify={"center"}>
                        {!surname && <Skeleton.Avatar active size={150} />}
                        {surname && <UserAvatar id={id} />}
                      </Row>
                      <Row justify={"center"}>
                        {!surname && <Skeleton.Button />}
                        {surname && (
                          <Title
                            level={3}
                            style={{ textAlign: "center", margin: 0 }}>
                            {surname}
                          </Title>
                        )}
                      </Row>
                    </Space>
                  </Row>
                </Row>
              </Col>
              <Col span={24} lg={18}>
                <Card bordered={false}>
                  <UserGeneralData setSurname={setSurname} />
                  {id && <UserContacts />}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
