import { useQuery } from "@apollo/client";
import { Skeleton } from "antd";
import { useState } from "react";
import { UpdateAvatar } from "../../../../../components/avatar";
import { useMain } from "../../../../../contexts/main";
import { clientAuth } from "../../../../../services/graphql.service";
import { getUserAvatarByIdQuery } from "../../../../account/services/account.service";

interface UserAvatar {
  id: string | undefined;
}

export const UserAvatar: React.FC<UserAvatar> = (props) => {
  const { id } = props;
  const { errorMessage } = useMain();
  const [avatar, setAvatar] = useState<any>();
  const [src, setSrc] = useState<any>();

  const { loading } = useQuery(getUserAvatarByIdQuery(), {
    client: clientAuth,
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      const { data } = result.avatarById;
      setAvatar(data);
      setSrc(data?.url);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  return (
    <>
      {loading && <Skeleton.Avatar active size={150} />}
      {!loading && (
        <UpdateAvatar
          // size={{
          //   xs: 55,
          //   sm: 60,
          //   md: 90,
          //   lg: 120,
          //   xl: 180,
          //   xxl: 200,
          // }}
          bucketName="533267310281-b2l-temp"
          collectionName={avatar?.collectionName}
          fieldName={avatar?.fieldName}
          src={src}
          setSrc={setSrc}
          id={id || ""}
          onCancel={() => {}}
        />
      )}
    </>
  );
};
