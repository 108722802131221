import { Navigate } from "react-router-dom";
import { AppRoute } from "../../models/app-route.model";
import { DebtorsRoutes } from "../debtors/routes.enum";
import { Login } from "./pages";
import { AutoLogin } from "./pages/auto-login/AutoLogin";
import { ForgotPassword } from "./pages/forgot-password/ForgotPassword";
import { Logout } from "./pages/login-redirect/Logout";
import { ResetPassword } from "./pages/reset-password/ResetPassword";
import { AuthRoutes } from "./routes.enum";

const getAuthRoutes = (): AppRoute[] => [
  new AppRoute({
    path: "*",
    component: <Navigate to={DebtorsRoutes.Leads} />,
    permission: ["admin-system", "admin", "user"],
  }),
  new AppRoute({
    path: AuthRoutes.LOGIN,
    component: <Login />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  // new AppRoute({
  //   path: AuthRoutes.REGISTER,
  //   component: <Register />,
  //   notUseMainLayout: true
  // }),
  new AppRoute({
    path: AuthRoutes.AUTOLOGIN,
    component: <AutoLogin />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.LOGOUT,
    component: <Logout />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    notUseMainLayout: true,
    notPermission: true,
  }),
  new AppRoute({
    path: AuthRoutes.RESET_PASSWORD,
    component: <ResetPassword />,
    notUseMainLayout: true,
    notPermission: true,
  }),
];

export { getAuthRoutes };

