import {
  Button,
  Card,
  Col,
  FormListFieldData,
  FormListOperation,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import style from "./UserContacts.module.scss";
import { MdCopyAll, MdDelete, MdEdit } from "react-icons/md";
import { UserContactsFunctions } from "./UserContactsFunctions";
import {
  ProForm,
  ProFormList,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { typeContactOptions } from "../../../../account/interfaces/UserContact";

export const UserContacts = () => {
  const { form, formRef, onFinish, loadingGet, edit, setEdit } =
    UserContactsFunctions();

  return (
    <Row>
      <Col span={24}>
        <Card
          size={"small"}
          title={"Contatos"}
          bordered={false}
          extra={
            <Button
              type="link"
              shape="circle"
              icon={<MdEdit />}
              onClick={() => setEdit(!edit)}
            />
          }>
          {loadingGet && <Skeleton active />}
          <ProForm
            //form={form}
            hidden={loadingGet}
            disabled={!edit}
            formRef={formRef}
            onFinish={async (values) => {
              await onFinish(values);
              return true;
            }}
            style={{ overflow: "auto" }}
            submitter={{
              render: (props, doms) => {
                return [
                  <Row hidden={!edit} justify={"end"}>
                    <Space>
                      <Button
                        type="primary"
                        key="submit"
                        onClick={() => props.form?.submit?.()}>
                        Salvar
                      </Button>
                    </Space>
                  </Row>,
                ];
              },
            }}>
            <ProFormList
              name={["geralData", "contacts"]}
              style={{ minWidth: "800px" }}
              creatorButtonProps={{
                creatorButtonText: "Adicionar novo contato",
              }}
              actionRender={(
                field: FormListFieldData,
                action: FormListOperation
              ) => [
                <Tooltip title="Duplicar contato">
                  <Button
                    type="link"
                    shape="circle"
                    icon={<MdCopyAll />}
                    style={{ marginTop: "1rem" }}
                    onClick={() =>
                      action.add(
                        formRef.current
                          ?.getFieldsValue()
                          ?.geralData?.contacts?.find(
                            (_: any, index: number) => index == field.name
                          )
                      )
                    }
                  />
                </Tooltip>,
                <Tooltip title="Deletar contato">
                  <Button
                    type="link"
                    shape="circle"
                    icon={<MdDelete />}
                    style={{ marginTop: "1rem" }}
                    onClick={() => action.remove(field.name)}
                  />
                </Tooltip>,
              ]}>
              <ProForm.Group
                key="group"
                size={8}
                style={{ width: "fit-content" }}>
                <ProFormText hidden name="id" />
                <ProFormText
                  width={"sm"}
                  name="description"
                  label="Descrição"
                />
                <ProFormSelect
                  width={"sm"}
                  options={typeContactOptions()}
                  name="type"
                  label="Tipo do contato"
                />
                <ProFormText name="value" label="Valor" />
              </ProForm.Group>
            </ProFormList>
          </ProForm>
        </Card>
      </Col>
    </Row>
  );
};
