import classNames from "classnames";
import React from "react";
import styles from "./FullLogo.module.scss";

interface FullLogoProps extends React.HTMLProps<HTMLDivElement> {
  white?: boolean;
}

export const FullLogo: React.FC<FullLogoProps> = ({ white, className }) => {
  return (
    <div className={classNames([styles.content, className])}>
      {white ? (
        <img className={styles.image} src="/images/logo-white.png" />
      ) : (
        <img className={styles.image} src="/images/logo.png" />
      )}
      <h1 className={styles.logo}>
        Linkfy <span className={styles["logo-light"]}>Scale</span>
      </h1>
    </div>
  );
};
