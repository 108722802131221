import { useCallback } from "react";

const useUtils = () => {
  const getOnlyNumber = useCallback(
    (value = "") => value?.replace(/\D/g, ""),
    []
  );

  const formatDateWithDayMonth = (date: string): string => {
    const formattedDate = new Date(date).toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      timeZone: "UTC",
    });
    return formattedDate;
  };

  const formatReal = useCallback(
    (value: string | number): string => {
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(Number(value));

      return formattedValue;
    },
    [getOnlyNumber]
  );
  
  const formatRealForNumeric = useCallback((value: string | number): string => {
    const numericValue = getOnlyNumber(value?.toString());
    const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    }).format(Number(numericValue) / 100);

    return formattedValue;
}, [getOnlyNumber]);

  const resetRealToNumber = useCallback((value: string): number => {
    const numericString = value.replace(/[^\d,]/g, "");
    const numericValue = parseFloat(numericString.replace(",", "."));

    return isNaN(numericValue) ? 0 : numericValue;
  }, []);

  const formatterNumber = (val: any) => {
    if (!val) return 0;
    return `${val}`
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.(?=\d{0,2}$)/g, ",");
  };

  const parserNumber = (val: any) => {
    if (!val) return 0;
    return Number.parseFloat(
      val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
    ).toFixed(2);
  };
  return {
    formatDateWithDayMonth,
    formatReal,
    formatRealForNumeric,
    getOnlyNumber,
    resetRealToNumber,
    formatterNumber,
    parserNumber
  };
};

export default useUtils;
