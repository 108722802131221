import { Form } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserRequestInterface } from "../../../interfaces/userRequest.interface";
import { AuthService } from "../../../services/auth.service";
import { useMutation } from "@apollo/client";
import { clientAuth } from "../../../../../services/graphql.service";
import { AuthRoutes } from "../../../routes.enum";
import { LocalStorageService } from "../../../../../services/local-storage.service";
import { useMain } from "../../../../../contexts/main";

export const ResetPasswordFormFunctions = () => {
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { successMessage, errorMessage } = useMain();
  const [searchParams] = useSearchParams();
  const { resetPassword } = AuthService();
  const [resetPasswordMutation, { loading: loadingRegister }] = useMutation(
    resetPassword(),
    {
      client: clientAuth,
    }
  );

  useEffect(() => {
    LocalStorageService.removeAllLocalStorage();
  }, []);

  const handleLogin = () => {
    navigate(AuthRoutes.LOGIN);
  };

  const validateMessages = {
    required: "${label} é obrigatorio!",
    types: {
      email: "${label} não é válido",
    },
  };

  const onSubmit = (values: UserRequestInterface) => {
    resetPasswordMutation({
      variables: {
        request: {
          ...values,
          token: searchParams.get("token"),
        },
      },
    }).then((result) => {
      const { success, message } = result.data.resetPassword;
      if (success) {
        navigate(AuthRoutes.LOGIN);
        successMessage("Senha alterada com sucesso.");
      } else {
        if (message) errorMessage(`${message}`);
        else
          errorMessage(
            `Ocorreu um erro inesperado. Tente novamente mais tarde.`
          );
      }
    });
  };

  return {
    handleLogin,
    validateMessages,
    form,
    passwordVisible,
    setPasswordVisible,
    onSubmit,
    loadingRegister,
  };
};
