import { gql, DocumentNode } from "@apollo/client";

function getUserByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
        surname
        name
        email`;
  }

  return gql`
      query Users($id: ID!) {
        userById(id: $id) {
          id
          ${columnsResponse}
        }
      }
    `;
}

function getUserAvatarByIdQuery(): DocumentNode {
  return gql`
    query Users($id: ID!) {
      avatarById(id: $id) {
        success
        errorMessage
        message
        data {
          collectionName
          fieldName
          url
        }
      }
    }
  `;
}

function updateUser(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUser($id: ID!, $request: UpdateUserRequestInput!) {
      updateUser(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function changePassword(): DocumentNode {
  const CREATE = gql`
    mutation ChangePassword($request: ChangePasswordRequestInput!) {
      changePassword(request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserContacts(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserContact($id: ID!, $request: UpdateContactsInput!) {
      updateUserContact(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export { changePassword, getUserAvatarByIdQuery, getUserByIdQuery, updateUser, updateUserContacts };
