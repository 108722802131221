import { useMutation, useQuery } from "@apollo/client";
import { UploadProps } from "antd";
import { ColumnsType } from "antd/es/table";
import Papa from "papaparse";
import { useCallback, useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import useUtils from "../../../../hooks/useUtils";
import { clientDebtors } from "../../../../services/graphql.service";
import {
  getLotesQuery,
  importDebtorsQuery,
} from "../../services/Debtors.services";
import { LoteModel } from "../ura-debtors/UraDebtors.functions";

export interface ImportDebtorsModel {
  documentNumber: string;
  name: string;
  value: number;
  valueFormat: string;
}

export const ImportDebtorsFunctions = () => {
  const { formatRealForNumeric } = useUtils();
  const [dataSourceDefault, setDataSourceDefault] = useState<
    ImportDebtorsModel[]
  >([]);
  const [dataSource, setDataSource] = useState<ImportDebtorsModel[]>([]);
  const { handleChangeBreadcumb, successMessage, errorMessage, setLoading } =
    useMain();

  const [batchs, setBatchs] = useState<LoteModel[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<LoteModel>();
  const [mailingUra, setMailingUra] = useState<boolean>();

  const { loading } = useQuery(getLotesQuery(), {
    client: clientDebtors,
    onCompleted: (result) => {
      const { data } = result.lotes;
      onCompletedLotes(data ?? []);
    },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const onCompletedLotes = (lotes: LoteModel[]) => {
    setBatchs(lotes ?? []);
  };

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Importar devedores",
      },
    ]);
  }, []);

  const propsUpload: UploadProps = {
    name: "file",
    accept: ".csv",
    customRequest({ onSuccess, onProgress }) {
      setTimeout(() => onProgress && onProgress({ percent: 50 }), 500);
      setTimeout(() => onProgress && onProgress({ percent: 75 }), 1000);
      setTimeout(() => onProgress && onProgress({ percent: 100 }), 1500);
      setTimeout(() => onSuccess && onSuccess("ok"), 2000);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log("uploading");
      }
      if (status === "done") {
        successMessage("Arquivo importado com sucesso!");
        changeHandler(info.file.originFileObj);
      } else if (status === "error") {
        errorMessage(
          `Ocorreu uma falha ao importar o arquivo ${info.file.name}.`
        );
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const columns = (): ColumnsType<ImportDebtorsModel> => [
    {
      title: "CNPJ",
      dataIndex: "documentNumber",
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Nome",
      dataIndex: "name",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Valor",
      dataIndex: "valueFormat",
      ellipsis: true,
      sorter: true,
    },
  ];

  const changeHandler = (file: any) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        handleForce(results.data);
      },
    });
  };

  const handleForce = (data: any) => {
    const newList = data
      .map(
        (item: any) => Object.keys(item).map((key) => [key, item[key]])[0][1]
      )
      .filter((item: string) =>
        /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/.test(item.substring(0, 18))
      )
      .map((item: string) => {
        const columnsItem = item.split(";");
        if (!columnsItem || !columnsItem[0] || !columnsItem[1]) return null;
        return {
          documentNumber: columnsItem[0].replaceAll(/\"/g, ""),
          name: columnsItem[1].replaceAll(/\"/g, ""),
          value: parseFloat(
            columnsItem[3]
              .replaceAll(/\"/g, "")
              .replaceAll(/\./g, "")
              .replace(",", ".")
          ),
          valueFormat: formatRealForNumeric(
            columnsItem[3]?.replaceAll(/\"/g, "") ?? "0"
          ),
        };
      })
      .filter((item: any) => item != null);
    setDataSource(newList);
    setDataSourceDefault(newList);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectAll = () => {
    if (dataSource.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      const newSelectedRowKeys = dataSource.map((item) => item.documentNumber);
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onReset = () => {
    setDataSourceDefault([]);
  };

  const [sendImportDebtorsMutation] = useMutation(importDebtorsQuery(), {
    client: clientDebtors,
  });

  const [state, setState] = useState<string | null>();
  const [city, setCity] = useState<any | null>();

  const hasSelected = selectedRowKeys.length > 0 && state && selectedBatch;

  const onImport = () => {
    setLoading(true);
    const selecteds = dataSource.filter(
      (item: any) =>
        selectedRowKeys.findIndex((key) => key === item.documentNumber) > -1
    );
    if (!selecteds || selecteds.length === 0) {
      errorMessage(
        "É necessário selecionar pelo menos um devedor para importar."
      );
      setLoading(false);
      return;
    }

    sendImportDebtorsMutation({
      variables: {
        request: {
          leads: selecteds,
          state,
          city,
          deleteExisting: "N",
          mailingUra: mailingUra ? "Y" : "N",
          batchName: selectedBatch,
        },
      },
    })
      .then((result) => {
        if (result.errors) {
          errorMessage("Houve um erro ao realizar a importação dos devedores.");
          return;
        }

        const { success, message } = result.data.import;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a importação dos devedores."
          );
          return;
        } else {
          onSelectChange([]);
          successMessage(message ?? "Devedores importados com sucesso!");
        }
      })
      .catch((error) => {
        errorMessage("Houve um erro ao realizar a importação dos devedores.");
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeState = (value: string, option: any) => {
    setState(value);
  };

  const onChangeCity = (value: string, option: any) => {
    setCity(value);
  };

  const onChangeValueRange = (value: any) => {
    setSelectedRowKeys([]);
    setDataSource(
      dataSourceDefault.filter(
        (item: any) => item.value > value[0] && item.value < value[1]
      )
    );
  };

  const [cities, setCities] = useState<any | null>();
  const fetchStates = useCallback(async () => {
    const result = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = (await result?.json()) as [];
    return data
      ?.sort((a: any, b: any) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      })
      ?.map((state: any) => ({
        label: state.nome,
        value: state.sigla,
      }));
  }, []);

  const fetchCities = useCallback(async () => {
    const result = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = (await result?.json()) as [];
    setCities(
      data
        ?.sort((a: any, b: any) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        })
        ?.map((city: any) => ({
          label: city?.nome,
          value: city?.nome,
        }))
    );
  }, [state]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  return {
    columns,
    dataSource,
    dataSourceDefault,
    handleForce,
    papaparseOptions,
    changeHandler,
    onSelectAll,
    selectedRowKeys,
    rowSelection,
    hasSelected,
    onReset,
    propsUpload,
    onImport,
    onChangeState,
    onChangeCity,
    onChangeValueRange,
    fetchStates,
    cities,
    batchs,
    setSelectedBatch,
    mailingUra,
    setMailingUra,
    loading,
  };
};
