import { Col, Form } from "antd";
import { Button, PasswordField, TextField } from "../../../../../components";
import { FullLogo } from "../../../../../components/images/full-logo/FullLogo";
import { LoginFormFunctions } from "./LoginForm.functions";
import style from "./LoginForm.module.scss";

export const LoginForm = () => {
  const {
    validateMessages,
    form,
    passwordVisible,
    setPasswordVisible,
    handleForgotPassword,
    handleRegister,
    login,
    loadingLogin,
  } = LoginFormFunctions();

  return (
    <Col xs={24} className={style.content}>
      <FullLogo className={style.logo} />
      <Form
        name="login"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        validateMessages={validateMessages}
        form={form}
        className={style.form}
        onFinish={login}>
        <TextField
          name="userName"
          label="Usuário"
          rules={[{ required: true }]}
        />
        <PasswordField
          name="password"
          label="Senha"
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          rules={[{ required: true }]}
        />
        <a className={style["forgot-password"]} onClick={handleForgotPassword}>
          Esqueci minha senha
        </a>
        <Button
          loading={loadingLogin}
          type="primary"
          className={style.button}
          htmlType="submit">
          Entrar
        </Button>
        {/* <div className={style.footer}>
                    <div className={style.or}>
                        <hr className={style['line']} />
                        Ou
                        <hr className={style['line']} />
                    </div>
                    <a className={style['register']} onClick={handleRegister}>
                        Criar uma conta
                    </a>
                </div> */}
      </Form>
    </Col>
  );
};
