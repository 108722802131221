import { FaUsersGear } from "react-icons/fa6";
import { AppRoute } from "../../models/app-route.model";
import { UserForm } from "../account/pages/user-form/UserForm";
import { UsersAccount } from "../account/pages/users/Users";
import { MyUser } from "../my-account/pages/my-user/MyUser";
import { MyUserRoutes } from "../my-account/routes.enum";
import { AccountRoutes } from "./routes.enum";

const getAccountRoutes = (): AppRoute[] => [
  new AppRoute({
    name: "Meu usuário",
    path: MyUserRoutes.MyUser,
    component: <MyUser />,
    hideInMenu: true,
    permission: ["admin-system", "admin", "user"],
  }),
  new AppRoute({
    path: AccountRoutes.Users,
    component: <UsersAccount />,
    icon: <FaUsersGear />,
    name: "Usuários",
    routes: [
    ],
  }),
  new AppRoute({
    path: AccountRoutes.UserCreateUser,
    component: <UserForm />,
    name: "Novo usuário",
    hideInMenu: true,
  }),
  new AppRoute({
    path: AccountRoutes.UserEditUser,
    component: <UserForm />,
    name: "Editar o usuário",
    hideInMenu: true,
  }),
];

export { getAccountRoutes };

