import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Skeleton
} from "antd";
import { MdEdit } from "react-icons/md";
import style from "./UserGeneralData.module.scss";
import { UserGeneralDataFunctions } from "./UserGeneralDataFunctions";

interface UserGeneralDataProps {
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const UserGeneralData = (props: UserGeneralDataProps) => {
  const { setSurname } = props;
  const {
    id,
    form,
    formRef,
    onFinish,
    loading,
    loadingGet,
    edit,
    setEdit,
  } = UserGeneralDataFunctions({ setSurname });
  
  return (
    <Card
      title={"Dados Gerais"}
      bordered={false}
      extra={
        <Button
          type="link"
          shape="circle"
          icon={<MdEdit />}
          onClick={() => setEdit(!edit)}
        />
      }>
      <Skeleton loading={loadingGet} active />
      <Form
        form={form}
        ref={formRef}
        hidden={loadingGet}
        name="user-form"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ maxWidth: 1200 }}
        disabled={!edit}
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          label="Apelido"
          name="surname"
          rules={[
            { required: true, message: "O apelido é obrigatório!" },
            {
              max: 30,
              message: "O apelido pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input disabled={!edit || !!id} />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            { required: true, message: "O nome é obrigatório!" },
            {
              max: 50,
              message: "O nome pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            { required: true, message: "O e-mail é obrigatório!" },
            {
              max: 50,
              message: "O e-mail pode ter no máximo 30 caracteres!",
            },
          ]}>
          <Input disabled={!edit || !!id} />
        </Form.Item>

        <Form.Item
          label="Permissão"
          name="geralRoleId"
          rules={[{ required: true, message: "A permissão é obrigatório!" }]}>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                label: "Administrador de sistema",
                value: "admin-system",
              },
              {
                label: "Administrador",
                value: "admin",
              },
              {
                label: "Usuário",
                value: "user",
              },
            ]}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        {edit && (
          <Form.Item className={style["button-save"]} wrapperCol={{ span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Salvar
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};
