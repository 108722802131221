import { Col, Form } from "antd";
import { Button, PasswordField } from "../../../../../components";
import { FullLogo } from "../../../../../components/images/full-logo/FullLogo";
import { ResetPasswordFormFunctions } from "./ResetPasswordForm.functions";
import style from "./ResetPasswordForm.module.scss";

export const ResetPasswordForm = () => {
  const {
    validateMessages,
    form,
    handleLogin,
    passwordVisible,
    setPasswordVisible,
    onSubmit,
    loadingRegister,
  } = ResetPasswordFormFunctions();

  return (
    <Col xs={22} sm={16} lg={8} xl={6} className={style.content}>
      <FullLogo className={style.logo} />
      <Form
        name="reset-password"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        validateMessages={validateMessages}
        form={form}
        className={style.form}
        onFinish={onSubmit}>
        <PasswordField
          name="password"
          label="Senha"
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          rules={[{ required: true }]}
        />
        <PasswordField
          passwordVisible={passwordVisible}
          setPasswordVisible={setPasswordVisible}
          name="confirmPassword"
          dependencies={["password"]}
          label={`Confirmar senha`}
          rules={[
            {
              required: true,
              message: "Confirme a senha",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("As senhas não correspondem"));
              },
            }),
          ]}
        />
        <Button
          loading={loadingRegister}
          type="primary"
          className={style.button}
          htmlType="submit">
          Alterar senha
        </Button>
        <div className={style.footer}>
          <div className={style.or}>
            <hr className={style["line"]} />
            Ou
            <hr className={style["line"]} />
          </div>
          <a className={style["login"]} onClick={handleLogin}>
            Já tenho uma conta
          </a>
        </div>
      </Form>
    </Col>
  );
};
