import React, { Fragment, useState } from "react";
import style from "./UpdateAvatar.module.scss";
import { UpdateAvatarModal } from "./update-avatar-modal/UpdateAvatarModal";
import { IAvatarRequest } from "../../../models/avatar-request.interface";
import { Avatar, Button } from "antd";
import { MdEdit } from "react-icons/md";
import classNames from "classnames";

interface UpdateAvatarProps {
  bucketName: string;
  id: string;
  collectionName: string;
  fieldName: string;
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateAvatar?: (data: IAvatarRequest, params?: any) => Promise<Response>;
  onCancel?: () => void;
  params?: any;
  disabled?: boolean;
}

const UpdateAvatar: React.FC<UpdateAvatarProps> = (
  props: UpdateAvatarProps
) => {
  const {
    bucketName,
    id,
    collectionName,
    fieldName,
    src,
    setSrc,
    updateAvatar,
    params,
    disabled,
    onCancel,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className={style["box-avatar"]}>
      <Avatar
        size={{
          xs: 32,
          sm: 40,
          md: 64,
          lg: 80,
          xl: 100,
          xxl: 120,
        }}
        className={classNames([{ [style["avatar-hover"]]: !disabled }])}
        src={src}
      />
      {!disabled && (
        <Button
          type="primary"
          className={style["edit"]}
          icon={<MdEdit />}
          onClick={() => setOpenModal(true)}
        />
      )}
      {openModal && (
        <UpdateAvatarModal
          bucketName={bucketName}
          id={id}
          collectionName={collectionName}
          fieldName={fieldName}
          src={src}
          setSrc={setSrc}
          onCancel={() => {
            if (onCancel) onCancel();
            setOpenModal(false);
          }}
          open={openModal}
          updateAvatar={updateAvatar}
          params={params}
        />
      )}
    </div>
  );
};

export { UpdateAvatar };
