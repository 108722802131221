import {
  ColumnsState,
  ProDescriptionsItemProps,
} from "@ant-design/pro-components";
import { useQuery } from "@apollo/client";
import { Button, Col, Row, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { HiEye } from "react-icons/hi2";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useMain } from "../../../../contexts/main";
import useUtils from "../../../../hooks/useUtils";
import { clientDebtors } from "../../../../services/graphql.service";
import { mask } from "../../../../utils/mask";
import { Sort } from "../../../account/pages/users/Users.functions";
import { getDebtorByIdQuery } from "../../services/Debtors.services";

export interface DebtorsModel {
  id: string;
  documentNumber: string;
  createdAt: Date;
  name: string;
  fantasyName: string;
  value: number;
  valueFormat: string;
  status: string;
  state: string;
  city: string[];
  failure: {
    createdAt: Date;
    message: string;
  };
}

export interface PartnerModel {
  id: string;
  documentNumber: string;
  name: string;
  cellPhones: string[];
  emails: string[];
}

export const formatNameStatus = (status: string): string => {
  switch (status) {
    case "AWAIT_ENRICHED":
      return "Aguardando enriquecimento";
    case "GETTING_RICH":
      return "Sendo enriquecido";
    case "ENRICHED":
      return "Enriquecido";
    case "ENRICHED_WITH_FAILURE":
      return "Falha no enriquecimento";
    case "NO_PARTNERS":
      return "Sócios não encontrados";
    case "AWAIT_SEND_CALL":
      return "Aguardando envio para ligação";
    case "SEND_CALL":
      return "Enviado para ligação";
    case "SEND_CALL_WITH_FAILURE":
      return "Falha no envio para ligação";
    case "AWAIT_SEND_MESSAGE":
      return "Aguardando envio de mensagem no WhatsApp";
    case "SEND_MESSAGE":
      return "Enviado mensagem no WhatsApp";
    case "SEND_MESSAGE_WITH_FAILURE":
      return "Falha no envio da mensagem no WhatsApp";
    default:
      return "Importado";
  }
};

export const formatColorStatus = (status: string): string => {
  switch (status) {
    case "ENRICHED":
      return "warning";
    case "GETTING_RICH":
      return "processing";
    case "AWAIT_ENRICHED":
      return "processing";
    case "ENRICHED_WITH_FAILURE":
      return "error";
    case "NO_PARTNERS":
      return "error";
    case "AWAIT_SEND_CALL":
      return "processing";
    case "SEND_CALL":
      return "processing";
    case "SEND_CALL_WITH_FAILURE":
      return "error";
    case "AWAIT_SEND_MESSAGE":
      return "success";
    case "SEND_MESSAGE":
      return "success";
    case "SEND_MESSAGE_WITH_FAILURE":
      return "error";
    default:
      return "default";
  }
};

export const DebtorsFunctions = () => {
  const { formatReal, formatterNumber, parserNumber } = useUtils();
  const { handleChangeBreadcumb, successMessage, errorMessage, setLoading } =
    useMain();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entity, setEntity] = useState<DebtorsModel | null>();
  const { refetch } = useQuery(getDebtorByIdQuery(), {
    client: clientDebtors,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onError: () => {},
  });

  const handleOpenCloseModal = (entity?: DebtorsModel) => {
    if (!entity) {
      setIsModalOpen(false);
      setEntity(null);
      return;
    }
    setIsModalOpen(!isModalOpen);
    setEntity(entity);
  };

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Leads",
      },
    ]);
  }, []);

  const columns = (): ProColumnsProps<DebtorsModel>[] => [
    {
      title: "CNPJ",
      dataIndex: "documentNumber",
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Nome",
      width: "25%",
      dataIndex: "name",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Estado",
      dataIndex: "state",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Cidade",
      dataIndex: "city",
      ellipsis: true,
      filtered: true,
      sorter: true,
      render(dom, entity) {
        return (
          <Row gutter={[8, 8]}>
            {entity.city?.map((city) => (
              <Col>
                <Tag>{city}</Tag>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: "Nome Fantasia",
      dataIndex: "fantasyName",
      search: false,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Valor",
      dataIndex: "value",
      ellipsis: true,
      sorter: true,
      valueType: "digitRange",
      fieldProps: {
        formatter: (value: any) => formatterNumber(value),
        parser: (value: any) => parserNumber(value),
      },
      render(dom, entity) {
        return <span>{formatReal(entity.value)}</span>;
      },
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      filtered: true,
      sorter: true,
      valueType: "dateRange",
      render: (dom, entity) => {
        return new Date(entity.createdAt).toLocaleString("pt-BR");
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      valueType: "select",
      request: async (params, props) => {
        return [
          {
            label: "Importado",
            value: "IMPORTED",
          },
          {
            label: "Sócios não encontrados",
            value: "NO_PARTNERS",
          },
          {
            label: "Sendo enriquecido",
            value: "GETTING_RICH",
          },
          {
            label: "Aguardando enriquecimento",
            value: "AWAIT_ENRICHED",
          },
          {
            label: "Enriquecido",
            value: "ENRICHED",
          },
          {
            label: "Falha no enriquecimento",
            value: "ENRICHED_WITH_FAILURE",
          },
          {
            label: "Aguardando envio para ligação",
            value: "AWAIT_SEND_CALL",
          },
          {
            label: "Enviado para ligação",
            value: "SEND_CALL",
          },
          {
            label: "Falha no envio para ligação",
            value: "SEND_CALL_WITH_FAILURE",
          },
          {
            label: "Aguardando envio de mensagem no WhatsApp",
            value: "AWAIT_SEND_MESSAGE",
          },
          {
            label: "Enviado mensagem no WhatsApp",
            value: "SEND_MESSAGE",
          },
          {
            label: "Falha no envio da mensagem no WhatsApp",
            value: "SEND_MESSAGE_WITH_FAILURE",
          },
        ];
      },
      render(dom, entity) {
        return (
          <Tag color={formatColorStatus(entity.status)}>
            {formatNameStatus(entity.status)}
          </Tag>
        );
      },
    },
    {
      title: "Falha",
      dataIndex: "failure",
      search: false,
      ellipsis: true,
      sorter: true,
      render(value, record, index) {
        return (
          record.failure?.message && (
            <Row>
              <Col span={24}>
                <Typography.Text ellipsis>
                  {record.failure?.message}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text style={{ fontSize: "12px" }} ellipsis>
                  {new Date(record.failure?.createdAt).toLocaleString("pt-BR")}
                </Typography.Text>
              </Col>
            </Row>
          )
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "action",
      search: false,
      render(dom, entity, index, action, schema) {
        return (
          <Row>
            <Col>
              <Button
                type="text"
                shape="circle"
                onClick={() => handleOpenCloseModal(entity)}
                icon={<HiEye />}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const columnsPartners = (): ProDescriptionsItemProps<PartnerModel>[] => [
    {
      title: "CPF",
      dataIndex: "documentNumber",
      ellipsis: true,
      render(dom, entity) {
        return <span>{mask(entity.documentNumber, "999.999.999-99")}</span>;
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Emails",
      dataIndex: "emails",
      ellipsis: true,
      render(dom, entity) {
        return (
          <Row gutter={[0, 8]}>
            {entity.emails?.map((email) => (
              <Tag>{email}</Tag>
            ))}
          </Row>
        );
      },
    },
    {
      title: "Celulares",
      dataIndex: "cellPhones",
      ellipsis: true,
      render(dom, entity) {
        return (
          <Row gutter={[0, 8]}>
            {entity.cellPhones?.map((cellPhone) => (
              <Tag>
                {mask(
                  cellPhone,
                  cellPhone.length == 11 ? "(99) 9 9999-9999" : "(99) 9999-9999"
                )}
              </Tag>
            ))}
          </Row>
        );
      },
    },
  ];

  const whereDefault = {
    documentNumber: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 50,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    documentNumber: {
      show: true,
    },
    name: {
      disable: true,
      show: true,
    },
    state: {
      show: true,
    },
    city: {
      show: true,
    },
    fantasyName: {
      show: false,
    },
    createdAt: {
      show: true,
    },
    value: {
      show: true,
    },
    status: {
      show: true,
    },
    failure: {
      show: false,
    },
  } as Record<string, ColumnsState>;

  const [columnsCsv, setColumnsCsv] = useState<string[]>([
    "documentNumber",
    "name",
    "state",
    "city",
    "fantasyName",
    "createdAt",
    "value",
    "partners",
  ]);

  return {
    columns,
    columnsMap,
    clientDebtors,
    whereDefault,
    variables,
    setVariables,
    isModalOpen,
    entity,
    handleOpenCloseModal,
    refetch,
    columnsPartners,
    columnsCsv,
  };
};
