import { message } from "antd";
import React, { createContext, useState, useContext, useEffect } from "react";
import { UserInterface } from "../models/user.interface";
import { LocalStorageService } from "../services/local-storage.service";
import { BreadcrumbItem } from "../components/breadcrumb";
import { decodeToken } from "react-jwt";
import { DocumentNode } from "graphql";
import { clientAuth } from "../services/graphql.service";
import { SimpleModel } from "../models/simple-model.interface";
import { getUserAvatarByIdQuery } from "../modules/account/services/account.service";
import { gql, useMutation, useQuery } from "@apollo/client";

function renewAccessToken(): DocumentNode {
  const LOGIN = gql`
    mutation RenewAccessToken($request: RenewTokenRequestInput!) {
      renewAccessToken(request: $request) {
        success
        message
        errorMessage
        data {
          accessToken
          refreshToken
        }
      }
    }
  `;

  return LOGIN;
}

export interface MainContextData {
  successMessage: (message: string) => void;
  errorMessage: (message: string) => void;
  infoMessage: (message: string) => void;
  warningMessage: (message: string) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  titlePage: string | null;
  setTitlePage: React.Dispatch<React.SetStateAction<string | null>>;
  handleToggleLoading(): void;
  breadcrumbsItens: BreadcrumbItem[];
  handleChangeBreadcumb(items: BreadcrumbItem[]): void;
  user?: UserInterface;
  srcAvatar?: string;
  handleRenewAccessToken(): void;
  updateUserDataObject(): void;
}

const MainContext = createContext<MainContextData>({} as MainContextData);

interface MainProviderProps {
  children: React.ReactNode;
}

const MainProvider: React.FC<MainProviderProps> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [titlePage, setTitlePage] = useState<string | null>(null);
  const storedUser = {} as UserInterface;
  const [breadcrumbsItens, setBreadcrumbsItens] = useState<BreadcrumbItem[]>([
    {
      title: "Home",
    },
  ]);

  const [user, setUser] = useState<UserInterface>(storedUser);
  const [srcAvatar, setSrcAvatar] = useState<string>();
  const { refetch } = useQuery(getUserAvatarByIdQuery(), {
    client: clientAuth,
    variables: { id: user?.Id },
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
    onError: () => {},
  });


  useEffect(() => {
    updateUserDataObject();
  }, []);

  const updateUserDataObject = async () => {
    try {
      const localUser = (LocalStorageService.getLocalStorageJSON<UserInterface>(
        LocalStorageService.localStorageUser
      ) ?? {}) as UserInterface;
      setUser(localUser);
      if (localUser?.Id) {
        refetch({ id: localUser?.Id }).then((result) => {
          const { data } = result.data.avatarById;
          setSrcAvatar(data?.url);
        });
      }
    } catch (error) {}
  };

  const [sendRenewAccessToken] = useMutation(renewAccessToken(), {
    client: clientAuth,
  });

  const handleRenewAccessToken = () => {
    const accessToken = LocalStorageService.getLocalStorage(
      LocalStorageService.localStorageToken
    );
    const refreshToken = LocalStorageService.getLocalStorage(
      LocalStorageService.localStorageRefreshToken
    );
    sendRenewAccessToken({
      variables: {
        request: {
          accessToken,
          refreshToken,
        },
      },
    }).then((result) => {
      if (result.errors) {
        console.log({ errors: result.errors });
        errorMessage("Houve um erro ao realizar a sua autenticação");
        setLoading(false);
        return;
      }

      const { data, success, message } = result.data.renewAccessToken;
      if (!data || !success) {
        errorMessage(
          `Houve um erro ao realizar a sua autenticação: ${message}`
        );
        setLoading(false);
        return;
      }

      const { accessToken, refreshToken } = data;
      LocalStorageService.setLocalStorage(
        LocalStorageService.localStorageToken,
        accessToken
      );
      LocalStorageService.setLocalStorage(
        LocalStorageService.localStorageRefreshToken,
        refreshToken
      );

      const user = decodeToken<any>(accessToken ?? "");
      if (user) {
        LocalStorageService.setLocalStorageJSON(
          LocalStorageService.localStorageUser,
          user
        );
      }

      updateUserDataObject();
      setLoading(false);
    });
  };

  const handleToggleLoading = () => {
    setLoading(!loading);
  };

  const successMessage = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 3,
    });
  };

  const errorMessage = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 3,
    });
  };

  const infoMessage = (message: string) => {
    messageApi.open({
      type: "info",
      content: message,
      duration: 3,
    });
  };

  const warningMessage = (message: string) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  const handleChangeBreadcumb = (items: BreadcrumbItem[]) => {
    if (items?.length > 0) {
      setBreadcrumbsItens(items);
    } else {
      setBreadcrumbsItens([
        {
          title: "Home",
        },
      ]);
    }
  };

  return (
    <MainContext.Provider
      value={{
        successMessage,
        errorMessage,
        infoMessage,
        warningMessage,
        loading,
        setLoading,
        titlePage,
        setTitlePage,
        handleToggleLoading,
        user,
        srcAvatar,
        handleRenewAccessToken,
        breadcrumbsItens,
        handleChangeBreadcumb,
        updateUserDataObject,
      }}>
      {contextHolder}
      {children}
    </MainContext.Provider>
  );
};

function useMain() {
  const context = useContext(MainContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { useMain, MainContext, MainProvider };
