import React, { useEffect, useState } from "react";
import { Form, FormInstance } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../../../../../contexts/main";
import {
  getUserByIdQuery,
  updateUser,
} from "../../../services/user.service";
import { clientAuth } from "../../../../../services/graphql.service";

interface UserGeneralDataFunctionsProps {
  setSurname: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const UserGeneralDataFunctions = (
  props: UserGeneralDataFunctionsProps
) => {
  const { errorMessage, user, loading, setLoading } = useMain();
  const { setSurname } = props;
  const id = user?.Id;
  const [edit, setEdit] = useState<boolean>();
  const navigate = useNavigate();

  const [form] = Form.useForm<{
    surname: string;
    name: string;
    email: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const { loading: loadingGet } = useQuery(getUserByIdQuery(), {
    client: clientAuth,
    variables: { id },
    onCompleted: (data) => {
      const user = data.userById;
      formRef.current?.setFieldsValue(user);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const surnameValue = Form.useWatch("surname", form);

  useEffect(() => {
    setSurname(surnameValue);
  }, [surnameValue]);

  const [sendUpdateUserMutation] = useMutation(updateUser(), {
    client: clientAuth,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const { name, surname, email } = values;

    if (id) {
      sendUpdateUserMutation({
        variables: {
          id,
          request: {
            name,
            surname,
            email,
          },
        },
      }).then((result) => {
        if (result.errors) {
          errorMessage("Houve um erro ao realizar a alterar o usuário.");
          return;
        }

        const { success, message } = result.data.updateUser;
        if (!success) {
          errorMessage(
            message ?? "Houve um erro ao realizar a alterar o usuário."
          );
          return;
        }
      })
      .catch((error) => {
        errorMessage("Houve um erro ao realizar a alterar o usuário.");
        console.log({error})
      })
      .finally(() => {
        setLoading(false);
        setEdit(false);
      });
    } else {
      errorMessage("Houve um erro ao alterar o seu usuário.");
    }
  };

  return {
    form,
    formRef,
    onFinish,
    loading,
    loadingGet,
    edit,
    setEdit,
  };
};
