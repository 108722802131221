import { useMutation, useQuery } from "@apollo/client";
import { Form, FormInstance } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMain } from "../../../../../contexts/main";
import { clientAuth } from "../../../../../services/graphql.service";
import {
  getUserByIdQuery,
  updateUserContacts
} from "../../../../account/services/account.service";

export const UserContactsFunctions = () => {
  const { errorMessage, setLoading } = useMain();
  const { id } = useParams();
  const [edit, setEdit] = useState<boolean>(!id);

  const [form] = Form.useForm<{
    surname: string;
    name: string;
    email: string;
  }>();
  const formRef = React.useRef<FormInstance>(null);

  const columnsResponse = `
    id
    geralData {
        contacts {
            description
            type
            value
        }
    }`;

  const { loading: loadingGet } = useQuery(getUserByIdQuery(columnsResponse), {
    client: clientAuth,
    variables: { id },
    onCompleted: (data) => {
      const user = data.userById;
      formRef.current?.setFieldsValue(user);
    },
    errorPolicy: "all",
    onError: ({ graphQLErrors, networkError }) => {
      if (!id) return;

      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          errorMessage(message)
        );
      if (networkError)
        errorMessage("Ocorreu um erro inesperado. Tente novamente mais tarde!");
    },
  });

  const [sendUpdateUserMutation] = useMutation(updateUserContacts(), {
    client: clientAuth,
  });

  const onFinish = (values: any) => {
    setLoading(true);
    const {
      geralData: { contacts },
    } = values;
    sendUpdateUserMutation({
      variables: {
        id,
        request: {
          contacts: contacts.map((_: any) => ({
            id: _.id,
            description: _.description,
            type: _.type,
            value: _.value,
          })),
        },
      },
    }).then((result) => {
      if (result.errors) {
        console.log({ errors: result.errors });
        errorMessage("Houve um erro ao realizar a alterar o usuário.");
        setLoading(false);
        return;
      }

      const { success } = result.data.updateUserContact;
      if (!success) {
        errorMessage("Houve um erro ao realizar a alterar o usuário.");
        setLoading(false);
        return;
      }
      setLoading(false);
      setEdit(false);
    });
  };

  return {
    onFinish,
    loadingGet,
    edit,
    setEdit,
    form,
    formRef,
  };
};
