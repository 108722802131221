import { FaMoneyBill } from "react-icons/fa6";
import { MdDatasetLinked, MdEditNote, MdOutlineLink, MdWhatsapp } from "react-icons/md";
import { AppRoute } from "../../models/app-route.model";
import { Debtors } from "./pages/debtors/Debtors";
import { ImportDebtors } from "./pages/import-debtors/ImportDebtors";
import { ImportDirectDebtors } from "./pages/import-direct-debtors/ImportDirectDebtors";
import { Integrations } from "./pages/integrations/Integrations";
import { SendWhatsApp } from "./pages/send-whatsapp/SendWhatsApp";
import { DebtorsRoutes } from "./routes.enum";

const getDebtorsRoutes = (): AppRoute[] => [
  new AppRoute({
    path: DebtorsRoutes.Leads,
    component: <Debtors />,
    icon: <FaMoneyBill />,
    name: "Leads importados",
  }),
  new AppRoute({
    path: DebtorsRoutes.ImportLeads,
    component: <ImportDebtors />,
    icon: <MdDatasetLinked />,
    name: "Integração Infoqualy/Discador",
  }),
  new AppRoute({
    path: DebtorsRoutes.SendWhatsApp,
    component: <SendWhatsApp />,
    icon: <MdWhatsapp />,
    name: "Integração Whatsapp",
  }),
  new AppRoute({
    path: DebtorsRoutes.IpboxIntegration,
    component: <ImportDirectDebtors />,
    icon: <MdOutlineLink />,
    name: "Integração direta com lote IPBOX",
  }),
  new AppRoute({
    path: DebtorsRoutes.Reports,
    component: <Integrations />,
    icon: <MdEditNote />,
    name: "Relatórios",
  })
];

export { getDebtorsRoutes };

