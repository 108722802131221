import {
  ProDescriptions,
  ProDescriptionsItemProps,
} from "@ant-design/pro-components";
import { DescriptionsProps } from "antd";

export interface ProDescriptionProps<T> extends DescriptionsProps {
  dataSource?: T;
  request?: (params: Record<string, T> | undefined) => Promise<T>;
  columns?: ProDescriptionsItemProps<Record<string, T>, "select" | "digit">[];
}

export const ProDescription = <T extends {}>(
  props: ProDescriptionProps<T>
): JSX.Element => {
  const { dataSource, columns, request } = props;
  return (
    <ProDescriptions
      layout="vertical"
      request={request}
      dataSource={dataSource}
      columns={columns}
      {...props}
    />
  );
};
