interface UserContact {
  id: string;
  contact: string;
  contactype: TypeContact;
  value: string;
}

enum TypeContact {
  PHONE = "Telefone",
  COMERCIAL_PHONE = "Telefone comercial",
  MAIL = "E-mail",
  COMERCIAL_MAIL = "E-mail comercial",
}

type EnumType = { [s: number]: string };
function mapEnum(enumerable: EnumType, fn: Function): any[] {
  let enumMembers: any[] = Object.keys(enumerable).map((key: any) => ({
    value: key,
    label: enumerable[key],
  }));
  let enumValues: any[] = enumMembers;
  return enumValues.map((m) => fn(m));
}

const typeContactOptions = () =>
  mapEnum(TypeContact, (value: any) => {
    return value;
  });

export { typeContactOptions, TypeContact };
export type { UserContact };
