import { Col, Form } from "antd";
import { Button, TextField } from "../../../../../components";
import { FullLogo } from "../../../../../components/images/full-logo/FullLogo";
import { ForgotPasswordFormFunctions } from "./ForgotPasswordForm.functions";
import style from "./ForgotPasswordForm.module.scss";

export const ForgotPasswordForm = () => {
  const { validateMessages, form, handleLogin, onSubmit, loadingRegister } =
    ForgotPasswordFormFunctions();

  return (
    <Col xs={24} className={style.content}>
      <FullLogo className={style.logo} />
      <Form
        name="register"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        validateMessages={validateMessages}
        form={form}
        className={style.form}
        onFinish={onSubmit}>
        <TextField name="email" label={`Email`} rules={[{ type: "email" }]} />
        <Button
          loading={loadingRegister}
          type="primary"
          className={style.button}
          htmlType="submit">
          Enviar nova senha
        </Button>
        <div className={style.footer}>
          <div className={style.or}>
            <hr className={style["line"]} />
            Ou
            <hr className={style["line"]} />
          </div>
          <a className={style["login"]} onClick={handleLogin}>
            Já tenho uma conta
          </a>
        </div>
      </Form>
    </Col>
  );
};
