import { ColumnsState } from "@ant-design/pro-components";
import { useQuery } from "@apollo/client";
import { Button, Col, Row, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { HiEye } from "react-icons/hi2";
import { MdDownload } from "react-icons/md";
import { ProColumnsProps } from "../../../../components/table/pro-table/ProTable";
import { useMain } from "../../../../contexts/main";
import { clientDebtors } from "../../../../services/graphql.service";
import { Sort } from "../../../account/pages/users/Users.functions";
import { getIntegrationByIdQuery } from "../../services/Debtors.services";

export interface IntegrationModel {
  id: string;
  documentNumber: string;
  createdAt: Date;
  status: string;
  batch: string;
  state: string;
  city: string[];
  documentNumberLeads: string[];
  applications: ApplicationModel[];
}

export interface ApplicationModel {
  name: string;
  metaData: {
    key: string;
    value: string;
  }[];
}

export const formatNameStatus = (status: string): string => {
  switch (status) {
    case "CREATED":
      return "Integração iniciada";
    case "ENRICHED":
      return "Leads enriquecidos";
    case "PARTNER_ENRICHED":
      return "Sócios enriquecidos";
    case "SEND_CALL":
      return "Enviado para ligação";
    case "SEND_MESSAGE":
      return "Enviado mensagem no WhatsApp";
    default:
      return "-";
  }
};

export const formatColorStatus = (status: string): string => {
  switch (status) {
    case "ENRICHED":
      return "processing";
    case "PARTNER_ENRICHED":
      return "processing";
    case "SEND_CALL":
      return "success";
    case "SEND_MESSAGE":
      return "success";
    default:
      return "default";
  }
};

export const IntegrationsFunctions = () => {
  const { handleChangeBreadcumb, successMessage, errorMessage, setLoading } =
    useMain();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entity, setEntity] = useState<IntegrationModel | null>();
  const { refetch } = useQuery(getIntegrationByIdQuery(), {
    client: clientDebtors,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onError: () => {},
  });

  const handleOpenCloseModal = (entity?: IntegrationModel) => {
    if (!entity) {
      setIsModalOpen(false);
      setEntity(null);
      return;
    }
    setIsModalOpen(!isModalOpen);
    setEntity(entity);
  };

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Home",
        path: "/",
      },
      {
        title: "Leads",
      },
    ]);
  }, []);

  const columns = (): ProColumnsProps<IntegrationModel>[] => [
    {
      title: "Estado",
      dataIndex: "state",
      ellipsis: true,
      filtered: true,
      sorter: true,
    },
    {
      title: "Cidade",
      dataIndex: "city",
      ellipsis: true,
      filtered: true,
      sorter: true,
      render(dom, entity) {
        return (
          <Row gutter={[8, 8]}>
            {entity.city?.map((city) => (
              <Col>
                <Tag>{city}</Tag>
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      title: "Lote",
      dataIndex: "batch",
      search: false,
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      filtered: true,
      sorter: true,
      valueType: "dateRange",
      render: (dom, entity) => {
        return new Date(entity.createdAt).toLocaleString("pt-BR");
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      filters: true,
      onFilter: true,
      ellipsis: true,
      sorter: true,
      valueType: "select",
      request: async (params, props) => {
        return [
          {
            label: "Integração iniciada",
            value: "CREATED",
          },
          {
            label: "Sócios enriquecidos",
            value: "PARTNER_ENRICHED",
          },
          {
            label: "Leads enriquecidos",
            value: "ENRICHED",
          },
          {
            label: "Enviado para ligação",
            value: "SEND_CALL",
          },
          {
            label: "Enviado mensagem no WhatsApp",
            value: "SEND_MESSAGE",
          },
        ];
      },
      render(dom, entity) {
        return (
          <Tag color={formatColorStatus(entity.status)}>
            {formatNameStatus(entity.status)}
          </Tag>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "action",
      search: false,
      render(dom, entity, index, action, schema) {
        return (
          <Row>
            <Col>
              <Button
                type="text"
                shape="circle"
                onClick={() => handleOpenCloseModal(entity)}
                icon={<HiEye />}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const columnsApps = (): ProColumnsProps<ApplicationModel>[] => [
    {
      dataIndex: "metaData",
      render(dom, entity, index, action, schema) {
        return (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              {entity.metaData?.map((meta) => {
                return (
                  <Row gutter={[4, 4]}>
                    <Col>
                      <Typography.Text style={{ fontWeight: "lighter" }}>
                        {meta.key}:
                      </Typography.Text>
                    </Col>
                    <Col>
                      {meta.key.includes("Arquivo") ? (
                        <a
                          href={meta.value}
                          target="_blank"
                          download={`${entity.name}_file`}>
                          <MdDownload />
                        </a>
                      ) : (
                        meta.value ?? "-"
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        );
      },
    },
  ];

  const whereDefault = {
    state: {
      contains: "",
    },
  };

  const [variables, setVariables] = useState({
    skip: 0,
    take: 50,
    where: whereDefault,
    order: { name: Sort.ASC },
  });

  const columnsMap = {
    batch: {
      show: true,
    },
    state: {
      disable: true,
      show: true,
    },
    city: {
      show: true,
    },
    createdAt: {
      show: true,
    },
    status: {
      show: true,
    },
  } as Record<string, ColumnsState>;

  const [columnsCsv, setColumnsCsv] = useState<string[]>([
    "documentNumber",
    "name",
    "state",
    "city",
    "fantasyName",
    "createdAt",
    "value",
    "partners",
  ]);

  return {
    columns,
    columnsMap,
    clientDebtors,
    whereDefault,
    variables,
    setVariables,
    isModalOpen,
    entity,
    handleOpenCloseModal,
    refetch,
    columnsApps,
    columnsCsv,
  };
};
