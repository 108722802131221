import { ProDescriptionsItemProps } from "@ant-design/pro-components";
import { Card, Col, Modal, Row, Typography } from "antd";
import { ProDescription } from "../../../../components/descriptions/pro-description/ProDescription";
import { ProTable } from "../../../../components/table/pro-table/ProTable";
import {
  ApplicationModel,
  IntegrationModel,
  IntegrationsFunctions,
} from "./Integrations.functions";

export const Integrations = () => {
  const {
    columns,
    columnsMap,
    clientDebtors,
    whereDefault,
    variables,
    setVariables,
    isModalOpen,
    handleOpenCloseModal,
    entity,
    refetch,
    columnsApps,
    columnsCsv,
  } = IntegrationsFunctions();

  return (
    <>
      <ProTable<IntegrationModel>
        headerTitle="Integrações"
        client={clientDebtors}
        queryName="integrations"
        whereType="IntegrationModelFilterInput"
        whereDefault={whereDefault}
        orderType="IntegrationModelSortInput"
        variables={variables}
        setVariables={setVariables}
        columns={columns()}
        defaultColumnsMap={columnsMap}
        columnsCsv={columnsCsv}
        rowKey={"id"}
      />
      {isModalOpen && (
        <Modal
          width={1200}
          open={isModalOpen}
          cancelText="Fechar"
          cancelButtonProps={{
            hidden: true,
          }}
          okButtonProps={{
            hidden: true,
          }}
          onCancel={() => handleOpenCloseModal()}>
          <ProDescription
            title="Integração"
            size="small"
            layout="horizontal"
            column={1}
            request={async (params) => {
              var result = await refetch({ id: entity?.id });
              var integration = result.data.integrations?.items;
              console.log({ integration, integration0: integration[0] });
              return { data: integration?.length > 0 ? integration[0] : null };
            }}
            columns={[
              ...columns()
                .filter((column: any) => column.dataIndex !== "action")
                .map(
                  (column) =>
                    ({
                      ...column,
                    } as ProDescriptionsItemProps)
                ),
              {
                title: "Apps",
                dataIndex: "applications",
                span: 2,
                render: (dom, entity) => (
                  <Row gutter={[8, 8]}>
                    {entity.applications?.map((app: ApplicationModel) => (
                      <Col span={24}>
                        <Card title={`${app.name}`}>
                          <ProDescription
                            dataSource={app}
                            column={1}
                            layout="horizontal"
                            size="small"
                            columns={
                              columnsApps() as ProDescriptionsItemProps[]
                            }
                          />
                        </Card>
                      </Col>
                    )) ?? (
                      <Typography.Text>Nenhum app integrado</Typography.Text>
                    )}
                  </Row>
                ),
              },
            ]}
          />
        </Modal>
      )}
    </>
  );
};
