import { Card, Col, Descriptions, Row, Skeleton, Space, Tag } from "antd";
import { UserCreateAccountFunctions } from "./MyUser.functions";
import style from "./MyUser.module.scss";
import Title from "antd/es/typography/Title";
import { UserGeneralData } from "./user-general-data/UserGeneralData";
import { UserContacts } from "./user-contacts/UserContacts";
import { UserAvatar } from "./user-avatar/UserAvatar";
import { UserSecurity } from "./user-security/UserSecurity";

export const MyUser = () => {
  const { surname, setSurname } =
    UserCreateAccountFunctions();

  return (
    <Row gutter={[24, 24]}>
      <Col span={24} lg={6}>
        <Row
          justify={"center"}
          style={{ position: "sticky", top: 0, gap: "24px" }}>
          <Row justify={"center"}>
            <Space direction="vertical">
              <Row justify={"center"}>
                {!surname && <Skeleton.Avatar active size={150} />}
                {surname && <UserAvatar />}
              </Row>
              <Row justify={"center"}>
                {!surname && <Skeleton.Button />}
                {surname && (
                  <Title level={3} style={{ textAlign: "center", margin: 0 }}>
                    {surname}
                  </Title>
                )}
              </Row>
            </Space>
          </Row>
        </Row>
      </Col>
      <Col span={24} lg={16}>
        <Card bordered={false} size={"small"}>
          <UserGeneralData setSurname={setSurname} />
          <UserContacts />
          <UserSecurity />
        </Card>
      </Col>
    </Row>
  );
};
