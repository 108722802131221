import {
  Button,
  Card,
  Form,
  FormInstance,
  FormListFieldData,
  FormListOperation,
  Input,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import style from "./UserContacts.module.scss";
import { ReactNode, useRef, useState } from "react";
import { MdCopyAll, MdDelete, MdEdit } from "react-icons/md";
import { UserContactsFunctions } from "./UserContactsFunctions";
import {
  TypeContact,
  typeContactOptions,
} from "../../../interfaces/UserContact";
import {
  FormListActionType,
  ProForm,
  ProFormDependency,
  ProFormList,
  ProFormSelect,
  ProFormText,
  TableItemSkeleton,
} from "@ant-design/pro-components";

export const UserContacts = () => {
  const { form, formRef, onFinish, loadingGet, edit, setEdit } =
    UserContactsFunctions();

  return (
    <Card
      size={"small"}
      title={"Contatos"}
      bordered={false}
      extra={
        <Button
          type="link"
          shape="circle"
          icon={<MdEdit />}
          onClick={() => setEdit(!edit)}
        />
      }>
      {loadingGet && <Skeleton active />}
      <ProForm
        //form={form}
        style={{ overflow: "auto" }}
        hidden={loadingGet}
        disabled={!edit}
        formRef={formRef}
        onFinish={async (values) => {
          await onFinish(values);
          return true;
        }}
        submitter={{
          render: (props, doms) => {
            return [
              <Row hidden={!edit} justify={"end"}>
                <Space>
                  <Button
                    type="primary"
                    key="submit"
                    onClick={() => props.form?.submit?.()}>
                    Salvar
                  </Button>
                </Space>
              </Row>,
            ];
          },
        }}>
        <ProFormList
          style={{ minWidth: "800px" }}
          name={["geralData", "contacts"]}
          creatorButtonProps={{
            creatorButtonText: "Adicionar novo contato",
          }}
          actionRender={(
            field: FormListFieldData,
            action: FormListOperation
          ) => [
            <Tooltip title="Duplicar contato">
              <Button
                type="link"
                shape="circle"
                icon={<MdCopyAll />}
                style={{ marginTop: "1rem" }}
                onClick={() =>
                  action.add(
                    formRef.current
                      ?.getFieldsValue()
                      ?.geralData?.contacts?.find(
                        (_: any, index: number) => index == field.name
                      )
                  )
                }
              />
            </Tooltip>,
            <Tooltip title="Deletar contato">
              <Button
                type="link"
                shape="circle"
                icon={<MdDelete />}
                style={{ marginTop: "1rem" }}
                onClick={() => action.remove(field.name)}
              />
            </Tooltip>,
          ]}>
          <ProForm.Group key="group">
            <ProFormText hidden name="id" />
            <ProFormText width="md" name="description" label="Descrição" />
            <ProFormSelect
              options={typeContactOptions()}
              width="md"
              name="type"
              label="Tipo do contato"
            />
            <ProFormText width="md" name="value" label="Valor" />
          </ProForm.Group>
        </ProFormList>
      </ProForm>
    </Card>
  );
};
