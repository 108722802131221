import { DocumentNode, gql } from "@apollo/client";

function getUserQuery(columnsResponse?: string): DocumentNode {
  const whereType = "UserModelFilterInput";
  const orderType = "UserModelSortInput";
  if (!columnsResponse) {
    columnsResponse = `
      surname
      name
      email`;
  }

  return gql`
    query Users($skip: Int!, $take: Int!, $where: ${whereType}!, $order: [${orderType}!]) {
      users(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
        items {
          id
          ${columnsResponse}
        }
      }
    }
  `;
}

function getUserByIdQuery(columnsResponse?: string): DocumentNode {
  if (!columnsResponse) {
    columnsResponse = `
      surname
      name
      email
      geralRole {
        key
        value
      }`;
  }

  return gql`
    query Users($id: ID!) {
      userById(id: $id) {
        id
        ${columnsResponse}
      }
    }
  `;
}

function getUserStatisticsQuery(): DocumentNode {
  return gql`
    query GetUsersStatistics {
      statistics {
        data {
          name
          value
          description
        }
      }
    }
  `;
}

function getUserAvatarByIdQuery(): DocumentNode {
  return gql`
    query Users($id: ID!) {
      avatarById(id: $id) {
        success
        errorMessage
        message
        data {
          collectionName
          fieldName
          url
        }
      }
    }
  `;
}

function createUser(): DocumentNode {
  return gql`
    mutation CreateUser($request: CreateUserRequestInput!) {
      createUser(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function createUserByAdmin(): DocumentNode {
  return gql`
    mutation CreateUserByAdmin($request: CreateUserByAdminRequestInput!) {
      createUserByAdmin(request: $request) {
        data {
          id
        }
        success
        message
        errorMessage
      }
    }
  `;
}

function updateUser(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUser($id: ID!, $request: UpdateUserRequestInput!) {
      updateUser(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserByAdmin(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserByAdmin(
      $id: ID!
      $request: UpdateUserByAdminRequestInput!
    ) {
      updateUserByAdmin(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserRole(): DocumentNode {
  const CREATE = gql`
    mutation UpdateRole($id: ID!, $request: UpdateUserByAdminRequestInput!) {
      updateUserByAdmin(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserModule(): DocumentNode {
  const CREATE = gql`
    mutation UpdateModule($id: ID!, $request: UpdateUserModuleRequestInput!) {
      updateUserModule(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function updateUserContacts(): DocumentNode {
  const CREATE = gql`
    mutation UpdateUserContact($id: ID!, $request: UpdateContactsInput!) {
      updateUserContact(id: $id, request: $request) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

function deleteUser(): DocumentNode {
  const CREATE = gql`
    mutation DeleteUser($id: ID!) {
      deleteUser(id: $id) {
        success
        message
        errorMessage
      }
    }
  `;

  return CREATE;
}

export {
  createUser,
  createUserByAdmin, deleteUser, getUserAvatarByIdQuery, getUserByIdQuery, getUserQuery,
  getUserStatisticsQuery, updateUser, updateUserByAdmin, updateUserContacts, updateUserModule, updateUserRole
};

