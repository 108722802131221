enum DebtorsRoutes {
  ImportLeads = '/import-leads',
  SendWhatsApp = '/send-whatsapp',
  IpboxIntegration = '/ipbox-integration',
  Leads = '/leads',
  Reports = '/reports',
}

export { DebtorsRoutes };

