type AnyObject = { [key: string]: any };

export function flattenTreeToList<T>(tree: T[], field: string): T[] {
  const result: T[] = [];
  for (let i = 0; i < tree.length; i++) {
    result.push(tree[i]);

    const chieldren = (tree[i] as any)[field];
    if (Array.isArray(chieldren)) {
      result.push(...flattenTreeToList<T>(chieldren, field));
    }
  }
  return result;
}

export const groupBy = (data: any, key: string, key1: string) => {
  return data.reduce(function (acc: any, item: any) {
    if (!Array.isArray(acc)) acc = [];
    acc = acc || [];
    const exist = acc.find((_: any) => _.name === item[key][key1]);
    if (exist) {
      exist.events.push(item);
    } else {
      acc.push({
        name: item[key][key1],
        order: item[key]["order"],
        events: [item],
      });
    }
    return Array.isArray(acc) ? acc : [];
  }, {});
};

export const flattenObject = (
  obj: AnyObject,
  parentKey: string = "",
  result: AnyObject = {}
): AnyObject => {
  Object.keys(obj).forEach((key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (key != "__typename") {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item: any, index: any) => {
          if (typeof item === "object" && item !== null) {
            flattenObject(item, `${newKey}[${index}]`, result);
          } else {
            result[`${newKey}[${index}]`] = item?.toString();
          }
        });
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key]?.toString();
      }
    }
  });

  return result;
};
