import type {
  ActionType,
  ColumnsState,
  ProColumns,
} from "@ant-design/pro-components";
import { ProTable as ProTableAntd } from "@ant-design/pro-components";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { Button, Col, Row } from "antd";
import { GetRowKey } from "antd/es/table/interface";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React from "react";
import { CSVLink } from "react-csv";
import { ProTableFunctions } from "./ProTable.functions";
import styles from "./ProTable.module.scss";

export type ProColumnsProps<T = any, ValueType = "text"> =
  | ProColumns<T, ValueType>
  | {
      permission: string[];
    };

export interface ProTableProps<T> {
  headerTitle?: string;
  client: ApolloClient<NormalizedCacheObject>;
  queryName: string;
  whereType: string;
  whereDefault: {};
  orderDefault?: {};
  orderType: string;
  columns: ProColumnsProps<T>[];
  defaultColumnsMap: Record<string, ColumnsState>;
  variables: React.SetStateAction<any>;
  setVariables: React.Dispatch<React.SetStateAction<any>>;
  setData?: React.Dispatch<React.SetStateAction<T[]>>;
  rowKey?: string | keyof T | GetRowKey<T> | undefined;
  fieldFilterCsv?: string;
  fieldFilterTypeCsv?: string;
  columnsCsv?: string[];
  ghost?: boolean | undefined;
  size?: SizeType;
  onFormatColumnsMap?: (columns: string | undefined) => string | undefined;
  onFormatColumnsMapCsv?: (columns: string | undefined) => string | undefined;
  toolBarRender?:
    | false
    | ((
        action: ActionType | undefined,
        rows: {
          selectedRowKeys?: (string | number)[] | undefined;
          selectedRows?: T[] | undefined;
        }
      ) => React.ReactNode[])
    | undefined;
  onSelectChangeDefault?: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

const ProTable = <T extends {}>(props: ProTableProps<T>): JSX.Element => {
  const {
    headerTitle,
    columns,
    defaultColumnsMap,
    setVariables,
    variables,
    client,
    queryName,
    whereType,
    whereDefault,
    orderDefault,
    orderType,
    onFormatColumnsMap,
    toolBarRender,
    setData,
    rowKey,
    fieldFilterCsv,
    fieldFilterTypeCsv,
    columnsCsv,
    onFormatColumnsMapCsv,
    onSelectChangeDefault,
    ghost,
    size,
  } = props;
  const {
    request,
    setColumnsMap,
    actionRef,
    hasPermission,
    rowSelection,
    handleExportCsv,
    exportCsvData,
    csvLink,
  } = ProTableFunctions({
    client,
    variables,
    setVariables,
    queryName,
    whereType,
    whereDefault,
    orderDefault,
    orderType,
    columns,
    defaultColumnsMap,
    onFormatColumnsMap,
    setData,
    rowKey,
    fieldFilterCsv,
    fieldFilterTypeCsv,
    columnsCsv,
    onFormatColumnsMapCsv,
    onSelectChangeDefault,
  });

  return (
    <>
      {fieldFilterCsv && fieldFilterTypeCsv && columnsCsv && (
        <Row
          gutter={[16, 16]}
          justify={"end"}
          style={{ padding: "0 1rem 1rem 0" }}>
          <Col>
            <Button type="primary" onClick={handleExportCsv}>
              Exportar para CSV
            </Button>
            <CSVLink
              ref={csvLink}
              title="Export to CSV"
              filename={"Expense_Table.csv"}
              data={exportCsvData as []}
            />
          </Col>
        </Row>
      )}
      <ProTableAntd<T>
        columns={
          columns.filter(
            (column: any) =>
              !column.permission || hasPermission(column.permission)
          ) as ProColumns<T>[]
        }
        actionRef={actionRef}
        sticky
        defaultSize={"small"}
        className={styles["root-table"]}
        rowKey={rowKey}
        ghost={ghost}
        size={size}
        rowSelection={rowSelection}
        showSorterTooltip
        request={request}
        columnsState={{
          defaultValue: defaultColumnsMap,
          onChange(value) {
            var columns = [];

            for (var column in value) {
              if (value[column].show) {
                columns.push(column);
              }
            }
            setColumnsMap(columns);
          },
        }}
        search={{
          labelWidth: "auto",
          layout: "vertical",
          defaultCollapsed: false,
          searchGutter: [16, 16],
          span: 6,
          style: ghost
            ? {
                padding: "0",
              }
            : {},
        }}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        pagination={{
          pageSize: variables?.take ?? 10,
          onShowSizeChange(current, size) {
            setVariables((actual: any) => ({
              ...actual,
              take: size,
              skip: current - 1,
            }));
          },
          pageSizeOptions: [5, 10, 20, 30, 50, 100, 300, 500, 1000],
          showSizeChanger: true,
        }}
        dateFormatter="string"
        headerTitle={headerTitle}
        toolBarRender={toolBarRender}
      />
    </>
  );
};

export { ProTable };

