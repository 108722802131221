import { ProConfigProvider } from "@ant-design/pro-components";
import { ConfigProvider, theme } from "antd";
import pt_BR from "antd/es/locale/pt_BR";
import "normalize.css";
import React, { useEffect, useState } from "react";
import { useMain } from "./contexts/main";
import "./index.scss";

export const useThemeDetector = () => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: any) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);
  return isDarkTheme;
};
const { darkAlgorithm, defaultAlgorithm } = theme;

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { user } = useMain();
  const isDarkTheme = useThemeDetector();

  return (
    <ConfigProvider
      locale={pt_BR}
      theme={{
        algorithm:
          !user?.Config?.Theme && isDarkTheme
            ? darkAlgorithm
            : user?.Config?.Theme == "dark"
            ? darkAlgorithm
            : defaultAlgorithm,
        token: {
          colorPrimary: "#032881",
          borderRadius: 6,

        }
      }}>
      <ProConfigProvider
        dark={
          !user?.Config?.Theme && isDarkTheme
            ? true
            : user?.Config?.Theme == "dark"
            ? true
            : false
        }
        token={{
          colorPrimary: "#032881",
          borderRadius: 6,
        }}>
        {children}
      </ProConfigProvider>
    </ConfigProvider>
  );
};
