import { Form } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../contexts/auth';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { AuthRoutes } from '../../../routes.enum';

const LoginFormFunctions = () => {
    const { login, loadingLogin } = useAuth();
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        LocalStorageService.removeAllLocalStorage();
    }, [])

    const handleRegister = () => {
        navigate(AuthRoutes.REGISTER)
    };

    const handleForgotPassword = () => {
        navigate(AuthRoutes.FORGOT_PASSWORD)
    };

    const validateMessages = {
        required: '${label} é obrigatorio!',
        types: {
            email: '${label} não é válido',
        },
    };

    return {
        validateMessages,
        form,
        passwordVisible,
        setPasswordVisible,
        handleRegister,
        handleForgotPassword,
        login,
        loadingLogin
    };
}

export { LoginFormFunctions };