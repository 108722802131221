import {
  GridContent,
  PageContainer,
  ProLayout,
} from "@ant-design/pro-components";
import {
  Avatar,
  Button,
  Col,
  MenuProps,
  Popover,
  Row,
  Tooltip,
  theme,
} from "antd";
import React, { useState } from "react";
import { MdLogout } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useMain } from "../../contexts/main";
import { useWindowSize } from "../../contexts/useWindowSize";
import { AppRoute } from "../../models/app-route.model";
import { MyUserRoutes } from "../../modules/my-account/routes.enum";
import { FullLogo } from "../images/full-logo/FullLogo";
import { Loading } from "../loadings/loading/Loading";
import style from "./Layout.module.scss";

interface LayoutProps {
  children: any;
  routes: AppRoute[];
}

type MenuItem = Required<MenuProps>["items"][number];

const { useToken } = theme;

export const Layout = (props: LayoutProps) => {
  const { routes, children } = props;
  const { token } = useToken();
  const [width] = useWindowSize();
  const { breadcrumbsItens, titlePage, user, srcAvatar, loading } = useMain();
  const { signOut: logout } = useAuth();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  function getItem(
    name: React.ReactNode,
    onClick: any,
    key: React.Key,
    icon?: React.ReactNode,
    path?: string,
    hideInMenu?: boolean,
    routes?: any[]
  ) {
    return {
      path,
      name,
      icon,
      hideInMenu,
      routes,
    };
  }

  const items = routes?.map((item, index) => {
    return getItem(
      item.name,
      () => {
        navigate(item.path!);
      },
      index + 1,
      item.icon,
      item?.path || "*",
      item?.hideInMenu,
      item.routes?.map((_, _index) =>
        getItem(
          _.name,
          () => {
            navigate(_.path!);
          },
          `${index + 1}-${_index + 1}`,
          _.icon,
          _.path,
          _.hideInMenu
        )
      )
    );
  });

  const myUser = () => {
    navigate(MyUserRoutes.MyUser);
  };

  return (
    <>
      <ProLayout
        className={style["layout"]}
        logo={"/images/logo.png"}
        title={false}
        layout="mix"
        route={{
          path: "*",
          title: "Linkfy Scale",
          routes: items,
        }}
        collapsed={collapsed}
        onCollapse={() => {
          setCollapsed(!collapsed);
        }}
        headerRender={(props, dom) => {
          return (
            <div
              style={{
                backgroundColor: token.colorPrimary,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <FullLogo white /> {dom}
            </div>
          );
        }}
        avatarProps={{
          src: srcAvatar,
          title: user?.Surname,
          render: (props, dom) => {
            return (
              <Popover
                trigger="click"
                content={
                  <Col span={24}>
                    <Row>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        onClick={myUser}>
                        {user?.Surname}
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        type="link"
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        icon={<MdLogout />}
                        onClick={logout}>
                        Sair
                      </Button>
                    </Row>
                  </Col>
                }>
                {width <= 767 ? <Avatar src={srcAvatar} /> : dom}
              </Popover>
            );
          },
        }}
        fixedHeader
        siderMenuType={"group"}
        menuItemRender={(item, dom) => (
          <Tooltip title={item.name}>
            <a
              onClick={() => {
                navigate(item.path || "/");
              }}>
              {dom}
            </a>
          </Tooltip>
        )}
        footerRender={(props) => {
          if (props?.collapsed) return undefined;
          return (
            <div
              style={{
                textAlign: "center",
                paddingBlockStart: 12,
              }}>
              <div>© {new Date().getFullYear()} Made with love</div>
              <div>by FA Code</div>
            </div>
          );
        }}>
        <PageContainer
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
          breadcrumb={{
            items: breadcrumbsItens,
            itemRender(route) {
              return route.onClick ? (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    if (route.onClick) route.onClick(e);
                  }}>
                  {route.title}
                </a>
              ) : (
                <Link to={route.path ?? ""}>{route.title}</Link>
              );
            },
          }}
          title={titlePage}
          childrenContentStyle={{ padding: "1rem" }}>
          <GridContent
            style={{
              height:
                width > 980 ? "calc(100vh - 200px)" : "calc(100vh - 200px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}>
            {children}
          </GridContent>
        </PageContainer>
      </ProLayout>
      {loading && <Loading />}
    </>
  );
};
