import { Form } from "antd";
import { IAvatarRequest } from "../../../../models/avatar-request.interface";
import { useMain } from "../../../../contexts/main";

const UpdateAvatarModalFunctions = (
  updateAvatar?: (data: IAvatarRequest, params?: any) => Promise<Response>,
  params?: any
) => {
  const { errorMessage, successMessage, setLoading } = useMain();
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    if (updateAvatar) {
      const valuesFormat = {
        ...values.avatar,
      };
      setLoading(true);
      try {
        const result = await updateAvatar(valuesFormat, params);
        if (result) {
          successMessage("Avatar atualizado com sucesso");
        } else {
          errorMessage("Erro ao atualizar avatar");
        }
      } catch (error) {
        errorMessage("Erro ao atualizar avatar");
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    form,
    handleSubmit,
  };
};

export { UpdateAvatarModalFunctions };
