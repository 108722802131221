import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const importDebtorsQuery = (): DocumentNode => {
  return gql`
    mutation ImportDebtors($request: ImportDebtorRequestInput!) {
      import(request: $request) {
        success
        message
        data {
          id
          documentNumber
          name
          value
        }
      }
    }
  `;
};

export const importNotEnrichedDebtorsQuery = (): DocumentNode => {
  return gql`
    mutation ImportNotEnrichedDebtors(
      $request: ImportNotEnrichedDebtorRequestInput!
    ) {
      importNotEnriched(request: $request) {
        success
        message
        data {
          id
          documentNumber
          name
          value
        }
      }
    }
  `;
};

export const updateSendWhatsAppQuery = (): DocumentNode => {
  return gql`
    mutation UpdateSendWhatsApp($request: UpdateSendWhatsAppRequestInput!) {
      updateSendWhatsApp(request: $request) {
        success
        message
        time
        environment
        router
        errorMessage
      }
    }
  `;
};

export const sendWhatsMessageAppQuery = (): DocumentNode => {
  return gql`
    mutation SendWhatsApp($request: SendWhatsAppRequestInput!) {
      sendWhatsApp(request: $request) {
        success
        message
        time
        environment
        router
        errorMessage
      }
    }
  `;
};

export const enrichedDebtorsQuery = (): DocumentNode => {
  return gql`
    mutation EnrichedDebtors($request: [EnrichedDebtorRequestInput!]!) {
      enriched(request: $request) {
        success
        message
        __typename
      }
    }
  `;
};

export const sendCallDebtorsQuery = (): DocumentNode => {
  return gql`
    mutation SendCallDebtors($request: ImportMailingRequestInput!) {
      sendCall(request: $request) {
        success
        message
        __typename
      }
    }
  `;
};

export const getEnrichedDebtorsQuery = (): DocumentNode => {
  return gql`
    query GetEnrichedDebtors(
      $skip: Int!
      $take: Int!
      $where: DebtorModelFilterInput!
      $order: [DebtorModelSortInput!]
    ) {
      debtors(skip: $skip, take: $take, where: $where, order: $order) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          __typename
        }
        totalCount
        items {
          id
          documentNumber
          name
          value
          status
          failure
          __typename
        }
        __typename
      }
    }
  `;
};

export const getDebtorByIdQuery = (): DocumentNode => {
  return gql`
    query getDebtor($id: ID!) {
      debtorById(id: $id) {
        data {
          id
          documentNumber
          name
          fantasyName
          value
          createdAt
          status
          state
          city
          failure {
            createdAt
            message
          }
          partners {
            id
            documentNumber
            name
            cellPhones
            emails
          }
        }
        __typename
      }
    }
  `;
};

export const exportCsvQuery = (): DocumentNode => {
  return gql`
    mutation exportCsv($documentsNumbers: [String!]!) {
      debtorsExportCsv(documentsNumbers: $documentsNumbers) {
        data {
          documentNumber
          name
          fantasyName
          value
          status
          failure {
            createdAt
            message
          }
          partners {
            documentNumber
            name
            cellPhones
            emails
          }
          __typename
        }
        __typename
      }
    }
  `;
};

export const getLotesQuery = (): DocumentNode => {
  return gql`
    query Lotes {
      lotes {
        success
        message
        errorMessage
        data {
          id
          descricao
        }
      }
    }
  `;
};

export const getIntegrationByIdQuery = (): DocumentNode => {
  return gql`
    query Integrations($id: String!) {
      integrations(where: { hashId: { eq: $id } }) {
        totalCount
        items {
          batch
          state
          status
          city
          documentNumberLeads
          id
          hashId
          deleted
          createdAt
          updatedAt
          applications {
            name
            metaData {
              key
              value
            }
          }
        }
      }
    }
  `;
};
